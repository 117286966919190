import { useEffect, useState } from "react";
import UserDTO from "../../dto/UserDTO";
import userService from "../../services/UserService";
import BCancel from "../Buttons/BCancel";
import BLeft from "../Buttons/BLeft";
import Search from "../Drugs/DrugSearch";
import ProfileSelector from "./ProfileSelector";

enum Status {
  loading,
  standard,
}

export default function DrugSearch() {
  // const [pzn, setPzn] = useState('')
  const [status, setStatus] = useState(Status.loading)
  const [user, setUser] = useState(new UserDTO())
  useEffect(() => {
    userService.get().then((u) => {
      if (!u) { return }
        setUser(u)
        setStatus(Status.standard)
    })
  }, [])
  return <>
    <div className='h-header '>
      <div>
        <BLeft
          target='/user/medikamente'
        />
      </div>
      <div>Medikament suchen</div>
      <div>
        <BCancel
          target='/user/medikamente'
        />
      </div>
    </div>
    <ProfileSelector />
    <Search
      set={async (pzn: string) => {
        // Set means save to user and then proceed to detail page
        const userdrug = await user.drugProfiles.addPzn(pzn) as {id: number}
        window.location.href = '/user/medikament/einstellungen/' + userdrug.id
      }}
    />
  </>
}