import MainMenu from "./MainMenu";
import TimeTable from '../Drugs/DrugPlan'
import BHamburger from "../Buttons/BHamburger";
import ProfileSelector from "./ProfileSelector";

export default function DrugPlan() {
  const headline = 'MediPlan'
  return <>
    <div className='h-header '>
    <div><BHamburger /></div>
      <div className='mobile-headheadline'>{headline}</div>
      <div></div>
    </div>
    <div className='page-headline'>
      <h2>{headline}</h2>
      <ProfileSelector />
    </div>
    <div className='h-main-content'>
      <TimeTable
      />
    </div>
    <MainMenu />
  </>
}