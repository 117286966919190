import { useState } from "react";
import Com from "../../services/ComService";
import i18n from "../../services/i18n";
import Input from "../Input/Input";
import {  } from "react-router-dom"

enum Status {
  init,
  done,
  shortPassword,
  noMatch,
}
export default function SetPasswordByToken() {
  const [Password, setPassword] = useState('')
  const [Password2, setPassword2] = useState('')
  const [S, setS] = useState(Status.init)
  const hash = window.location.href.match(/[^/]+$/)
  const send = async function() {
    if (Password !== Password2) {
      setS(Status.noMatch)
      return
    }
    const r = await Com('PATCH:user/passwordmail', {
      token: hash,
      password: Password
    }, 1) as {status: string}
    if (r.status === 'short password') {
      setS(Status.shortPassword)
      return
    }
    setS(Status.done)
  }
  const Content = () => {
    switch(S) {
      case Status.init:
      case Status.shortPassword:
      case Status.noMatch:
        return <>
          <div className='w3-container w3-container-auto w3-padding w3-margin w3-center'>
            {i18n.get('passwordForgottenExplainer')}
          </div>
          <div className='w3-container w3-container-auto w3-padding w3-margin w3-center'>
            <Input
              value={Password}
              cb={(c) => setPassword(c)}
              placeholder={i18n.get('newPassword')}
              type='password'
            />
            <Input
              value={Password2}
              cb={(c) => setPassword2(c)}
              placeholder={i18n.get('repeatNewPassword')}
              onEnter={() => {
                send()
              }}
              type='password'
            />
            <button
              className='button-primary'
              onClick={async () => {
                send()
              }}
            >Ok</button>
            {
              S === Status.shortPassword &&
              <div className='w3-card w3-auto w3-border w3-border-red w3-color-red w3-margin w3-padding'>
                {i18n.get('shortPassword')}
              </div>
            }
            {
              S === Status.noMatch &&
              <div className='w3-card w3-auto w3-border w3-border-red w3-color-red w3-margin w3-padding'>
                {i18n.get('passwordsDoNotMatch')}
              </div>
            }
          </div>
        </>
      default:
        return <div className="w3-container">
          
          <div className='w3-border w3-border-green w3-color-green w3-padding'>
            {i18n.get('passwordChanged')}
          </div>
          <button
            className='button-primary w3-center'
            onClick={async () => {
              window.location.href = '/'
            }}
          >{i18n.get('login')}</button>
        </div>
    }
  }
  
  return <div className='w3-container'>
    <h1>Passwort setzen</h1>
    {Content()}
  </div>
}
