export default class PropDTO {
  id: number
  key1: string
  key2: string
  value1: string
  value2: string

  constructor(data: IProp) {
    this.id = data.id || -1
    this.key1 = data.key1
    this.key2 = data.key2
    this.value1 = data.value1
    this.value2 = data.value2
  }
}

export interface IProp {
  id: number
  key1: string
  key2: string
  value1: string
  value2: string
}