import broadcastService from "../services/BroadcastService";
import Com from "../services/ComService";
import DrugDTO, { IDrug } from "./DrugDTO";
import { DrugProfileDTO, IDrugProfile } from "./DrugProfileDTO";
import { pushDTO } from "./PushDTO";

export default class DrugProfilesDTO {
  profiles: DrugProfileDTO[] = []
  currentProfile: number = 0
  selectedDrug: DrugDTO | null = null

  init(data: IDrugProfile[]) {
    this.profiles = data.map(i => new DrugProfileDTO(i))
  }

  private getCurrentProfile(id?: number) {
    const useId = id || this.currentProfile
    let profile = this.profiles.find(i => i.id === useId)
    if (!profile) {
      if (this.profiles.length > 0) {
        return this.profiles[0]
      } else {
        return false
      }
    }
    return profile
  }

  public setCurrentProfile(id: number) {
    this.currentProfile = id
  }

  public async createProfile(name: string) {
    const result = (await Com('PUT:user/drugProfile', {
      name: name
    })) as IDrugProfile
    const newProfile = new DrugProfileDTO(result)
    this.profiles.push(newProfile)
    this.currentProfile = result.id
    return newProfile
  }

  public async deleteProfile(id: number) {
    this.profiles = this.profiles.filter((p) => p.id !== id)
    await Com('DELETE:user/drugProfile', {
      id: id
    })
  }

  async calcDrugInteractions(id?: number) {
    let profile = this.getCurrentProfile(id)
    if (!profile) { return false}
    await profile.calcDrugInteractions()
    return true
  }

  public getDrugInteractions(id?: number) {
    let profile = this.getCurrentProfile(id)
    if (!profile) { return null }
    return profile.drugInteractions.get()
  }

  public drugInfo(pzn: string, id?: number) {
    let profile = this.getCurrentProfile(id)
    if (!profile) { return {langname: 'ErrNotFound'} }
    return profile.drugInfo(pzn)
  }

  public async getDrugsFromServer(id?: number) {
    let profile = this.getCurrentProfile(id)
    if (!profile) { return false }
    await profile.getDrugsFromServer()
    return true
  }

  public getDrugs(id?: number) {
    let profile = this.getCurrentProfile(id)
    if (!profile) { return [] }
    return profile.getDrugs()
  }

  public async addPzn(pzn: string, id?: number) {
    let profile = this.getCurrentProfile(id)
    if (!profile) {
      profile = await this.createProfile('Standard')
    }
    const userdrug = await profile.addPzn(pzn)
    return userdrug
  }

  public async removePzn(pzn: string, id?: number) {
    let profile = this.getCurrentProfile(id)
    if (!profile) { return false }
    await profile.removePzn(pzn)
    return true
  }

  public async getDrugsInfoFromServer(pzn: string) {
    const result = await Com('POST:user/drug/list', {
      pzns: pzn
    }) as IDrug[]
    // Update Druglist with information from server:
    return result.map(r => new DrugDTO(r))
  }

  public async getDrugsInfoByGTINFromServer(gtin: string) {
    const result = await Com('POST:user/drug/gtin', {
      gtin: gtin
    }) as IDrug[]
    // Update Druglist with information from server:
    return result.map(r => new DrugDTO(r))
  }

  public resetSelectedDrug() {
    this.selectedDrug = null
  }

  public getUserdrugById(id: number): DrugDTO | null {
    for (let i = 0, m = this.profiles.length; i < m; i++) {
      let drug = this.profiles[i].getUserdrugById(id)
      if (drug) { return drug }
    }
    return null
  }

  public getTodayIntakes(d?: Date, id?: number) {
    let profile = this.getCurrentProfile(id)
    if (!profile) { return null }
    return profile.getTodayIntakes(d)
  }

  public async profileHasNotifications(): Promise <boolean> {
    // If no subscription - the answer is no!

    console.log('profileHasNotifications start')
    const hasNotification = await pushDTO.hasNotification()
    console.log('profileHasNotifications hasNotification', hasNotification)
    if (!hasNotification) { return false }
    console.log('profileHasNotifications get current profile')
    // FOR CURRENT PROFILE
    let profile = this.getCurrentProfile()
    console.log('profileHasNotifications got curent profile', profile)
    // If no profile - the answer is no!
    if (!profile) { return false }
    console.log('profileHasNotifications profile okay')
    // If the parameter has not been set - the answer is no!
    const setValue = profile.props.getPropValue1('pushnotifications', 'switch')
    console.log('profileHasNotifications set value is', setValue, profile)
    return (setValue === 'on')
  }

  public async profileSwitchOnNotifications() {
    // FOR CURRENT PROFILE
    let profile = this.getCurrentProfile()
    if (!profile) { return }
    const newVal = await pushDTO.addProfilePush(profile.id) ? 'on' : 'off'
    await profile.props.setProp('pushnotifications', 'switch', newVal)
    if (newVal === 'on') {
      broadcastService.broadcast('toastMsg', {
        title: `Benachrichtigungen aktiviert`,
        body: `Benachrichtigungen erfolgreich aktiviert für Profil ${profile.name}.`
      })
      return true
    } else {
      broadcastService.broadcast('toastMsg', {
        title: `Benachrichtigungen nicht aktiviert`,
        body: `Benachrichtigungen konnten leider nicht aktiviert werden. Evtl fehlen hier Berechtigungen!`
      })
      return false
    }
  }

  public async profileSwitchOffNotifications() {
    // FOR CURRENT PROFILE
    let profile = this.getCurrentProfile()
    if (!profile) { return }
    // pushDTO.removeProfilePush(profile.id)
    await profile.props.setProp('pushnotifications', 'switch', 'off')
    broadcastService.broadcast('toastMsg', {
      title: `Benachrichtigungen erfolgreich deaktiviert`,
      body: `Benachrichtigungen erfolgreich deaktiviert für Profil ${profile.name}.`
    })
    return false
  }
}

