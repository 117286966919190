import i18n from "./i18n"


export function twoDigits(i: number) {
  if (i < 10) {
    return `0${i}`
  }
  return `${i}`
}

export function printUserTime(d: Date) {
  return `${twoDigits(d.getHours())}:${twoDigits(d.getMinutes())}`
}

export function printWeekday(d: Date) {
  const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]
  return i18n.get(days[d.getDay()])
}

export function printShortDate(d: Date) {
  return `${twoDigits(d.getDate())}.${twoDigits(d.getMonth() + 1)}.`
}

export function dateFloorHour(d: Date): Date {
  let nD = new Date(d)
  nD.setMinutes(0)
  nD.setSeconds(0)
  nD.setMilliseconds(0)
  return nD
}

export function dateCeilHour(d: Date): Date {
  let nD = dateFloorHour(d)
  nD.setHours(nD.getHours() + 1)
  return nD
}

export function isWeekend(d?: Date): boolean {
  const now = d || new Date()
  const day = now.getDay()
  return day === 0 || day === 6
}

export function date2UserDate(d: Date) {
  return `${twoDigits(d.getDate())}.${twoDigits(d.getMonth() + 1)}.${d.getFullYear()}`
}

export function stringDate2UserDate(stringDate: string) {
  const d = new Date(stringDate)
  return date2UserDate(d)
}

export function date2StringDate(d: Date) {
  return new Date(d).toJSON()
}

export function userDate2Date(userdate: string) {
  console.log('____userDate2Date', userdate)
  const scope2 = /([0-9]{4})-([0-9]{2})-([0-9]{2})/
  const c = userdate.match(scope2)
  if (c) {
    return new Date(userdate)
  }
  const scope1 = /([0-3]?[0-9])[^0-9]([0-1]?[0-9])[^0-9]([0-9]+)/
  const g = userdate.match(scope1)
  if (!g) { return new Date(userdate)}
  const d = parseInt(g[1], 10)
  const m = parseInt(g[2], 10) - 1
  let y = parseInt(g[3], 10)
  if (y < 100) {
    y += 2000
  }
  return new Date(y, m, d)
}

export function userDate2stringDate(userdate: string) {
  return date2StringDate(userDate2Date(userdate))
}
