import BHamburger from "../Buttons/BHamburger";
import MainMenu from "./MainMenu";
import './Chat.scss'
// <iframe title="externalchat" src='/chat.html' height="100%" width="100%" />
export default function Chat() {
  const headline = 'Chat'
  return <>
    <div className='h-header '>
      <div><BHamburger /></div>
      <div className='mobile-headheadline'>{headline}</div>
      <div></div>
    </div>
    <div className='h-main-content chatter'>
      <iframe title="externalchat" src="https://lp.chatwerk.de/?organizationId=iZvhxLtLNu&channelId=DbPONnrpPW&messenger=WhatsApp%25Instagram%25FacebookMessenger%25GoogleBM"  height="100%" width="100%" />
    </div>
    <MainMenu />
  </>
}