import de from '../i18n/de.json'

class I18n {
  get(key: string) {
    const currentDict = this.getCurrentDict()
    const t = currentDict.find(e => e[0] === key)
    if (t !== undefined) {
      return t[1]
    }
    return key
  }
  getCurrentDict(): [string, string][] {
    return de as unknown as [string, string][]
  }
}
const i18n = new I18n()
export default i18n