import config from '../config.json'
import { myFirebase } from '../services/firebase';

interface PushSubscription {
  endpoint: string;
  expirationTime: number | null;
  options: {
    applicationServerKey: Uint8Array | null;
    userVisibleOnly: boolean;
  },
  unsubscribe?: () => void;
  getKey: (name: 'p256dh' | 'auth') => ArrayBuffer | null;
}

type HTTPMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'OPTIONS' | 'HEAD' | 'PATCH';

const push_subscription = config.apiPrefix + 'push_subscription'

class PushDTO {

  isPushEnabled: boolean = false
  pushButton_disabled: boolean = false
  pushButton_textContent: string = ''
  initDone: boolean = false
  serviceWorkerAvailable: number = -1
  constructor() {
    this.hasServiceWorker().then(hasWorker => {
      if (hasWorker) {
        this.push_updateSubscription(-1)
      }
    })
  }

  public async hasServiceWorker() {
    if (this.serviceWorkerAvailable < 0) {
      return new Promise((success) => {
        window.addEventListener('load', () => {
          if ('serviceWorker' in navigator) {
            this.serviceWorkerAvailable = 1
            success(true)
          } else {
            this.serviceWorkerAvailable = 0
            success(false)
          }
        });
      })
    }
    return this.serviceWorkerAvailable === 1
  }

  pushServiceAvailable() : Promise <boolean> {
    return new Promise((success) => {
      success(true)
      
    })
    
  }

  changePushButtonState(state: string) {
    switch (state) {
      case 'enabled':
        this.pushButton_disabled = false;
        this.pushButton_textContent = 'Disable Push notifications';
        this.isPushEnabled = true;
        break;
      case 'disabled':
        this.pushButton_disabled = false;
        this.pushButton_textContent = 'Enable Push notifications';
        this.isPushEnabled = false;
        break;
      case 'computing':
        this.pushButton_disabled = true;
        this.pushButton_textContent = 'Loading...';
        break;
      case 'incompatible':
        this.pushButton_disabled = true;
        this.pushButton_textContent = 'Push notifications are not compatible with this browser';
        break;
      default:
        console.error('Unhandled push button state', state);
        break;
    }
  }

  public async hasNotification() {
    const hasWorker = await this.hasServiceWorker()
    if (!hasWorker) {
      console.log('no serviceworker in navigator - why not?', navigator)
      return false
    }
    console.log('has Notification - start')
    if (this.initDone) {
      console.log('has Notification - fast answer', this.isPushEnabled)
      return this.isPushEnabled
    }
    console.log('has Notification - slow answer')
    
    return new Promise(success => {
      navigator.serviceWorker.ready.then(async () => {
        const token = await myFirebase.getToken()
        if (token === 'err') { success(false) }
        this.isPushEnabled = true
        success(true)
      })
    })
  }

  async push_updateSubscription(profile_id: number) {
    // Update Subscription! Do not reregister!
    // this.addProfilePush(-1)
    const token = await myFirebase.getToken()
    if (token === 'err') { return }

  }

  /**
   * If push enabled, gather information about push! Otherwise do initial push!
   * @param profile_id
   */
  public async addProfilePush(profile_id: number) {
    
    // new firebase version:
    return new Promise(async (success) => {
      const token = await myFirebase.requestPermission()
      if (token === 'no') {
        success(false)
      }
      const method: HTTPMethod = 'PUT'
      const contentEncoding = 'aesgcm'
      fetch(push_subscription, {
        method,
        body: JSON.stringify({
          endpoint: 'firebase',
          publicKey: '',
          authToken: token,
          contentEncoding,
          // profile_id: profile_id,
        }),
        }).then(() => {
          success(true)
        }).catch(() => {
          success(false)
        })
    })
  }

  /**
   * Unsubscribe everything (for device)
   */
  public unsubscribe() {
    this.changePushButtonState('computing');
    const method: HTTPMethod = 'DELETE'
    const contentEncoding = 'aesgcm'
    fetch(push_subscription, {
      method,
      body: JSON.stringify({
        endpoint: 'firebase',
        publicKey: '',
        authToken: '',
        contentEncoding,
      }),
      }).then(() => {
        this.changePushButtonState('disabled');
      })
  }
}


export const pushDTO = new PushDTO()
