export default class ThingHolder {
  val: any = undefined
  constructor(val?: any) {
    if (val) {
      this.val = val
    }
  }
  set(val: any) {
    this.val = val
  }
  get(): any {
    return this.val
  }
}
