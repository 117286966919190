import { useState } from "react"
import { ArrowDownCircle, ArrowUpCircle, Plus, Trash } from "react-feather"
import GroupDTO, { Prop, FieldType, FieldTypes } from "../../dto/GroupDTO"
import Input from "../Input/Input"

export default function FieldsEditor(props: {
  ugroup: GroupDTO
}) {
  const [Rerender, SetRerender] = useState(0)
  const g = props.ugroup
  return <div>
    <h3>Felder</h3>
    <button
      className='button-primary'
      onClick={() => {
        g.addField()
        SetRerender(Rerender + 1)
      }}
    ><Plus /></button>
    {
      g.getFields().map((f, index) => <FieldEditor
          key={`field-${f.id}-${index}`}
          field={f}
          ugroup={g}
          cb={() => SetRerender(Rerender + 1)}
        />)
    }
  </div>
}

export function FieldEditor(props: {
  field: Prop,
  ugroup: GroupDTO,
  cb: () => void
}) {
  const g = props.ugroup
  const f = props.field
  const [name, setName] = useState(f.key2)
  const fieldType = f.value1 as FieldType
  const currentFieldType = FieldTypes.find(ft => ft.type === fieldType) || FieldTypes[0]
  const currentFieldTypeName = currentFieldType.name

  return <div className='w3-row w3-border w3-padding'>
    <div className='w3-col s3'>
      <div>Feldname:</div>
      <Input
        cb={(value) => {
          setName(value)
        }}
        onEnter={async () => {
          await g.nameField(f.id, name)
          props.cb()
        }}
        value={name}
      />
      {
        f.key2 !== name &&
        <button
          onClick={async () => {
            await g.nameField(f.id, name)
            props.cb()
          }}
        >
          Speichern
        </button>
      }
    </div>
    <div className='w3-col s4'>
      <div>
        Typ:
      </div>
      <div className="w3-dropdown-hover">
        <button className="w3-button w3-border">
          { currentFieldTypeName }
        </button>
        <div className="w3-dropdown-content w3-bar-block w3-border">
          {
            FieldTypes.map(ft => <div
                key={`fieldtype-${ft.type}`}
                className='w3-block w3-button w3-ripple'
                onClick={async () => {
                  await g.setFieldType(f.id, ft.type)
                  props.cb()
                }}
            >
              {ft.name}
            </div>)
          }
        </div>
      </div> 
    </div>
    <div className='w3-col s2'>
      <button
        className='w3-button'
        onClick={async () => {
          await g.moveField(f.id, -1)
          props.cb()
        }}
      ><ArrowUpCircle /></button>
      <button
        className='w3-button'
        onClick={async () => {
          await g.moveField(f.id, 1)
          props.cb()
        }}
      ><ArrowDownCircle /></button>
      <button
        className='w3-button w3-red'
        onClick={async () => {
          if(window.confirm('Feld wirklich löschen?')) {
            await g.deleteProp(f.id)
            props.cb()
          }
        }}
      >
        <Trash />
      </button>
    </div>
  </div>
}
