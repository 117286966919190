import { Edit, Plus, ToggleLeft, ToggleRight, Trash } from "react-feather";
import DrugDTO, { DrugConfigItem } from "../../dto/DrugDTO";
import { useState } from "react";
import PropDTO from "../../dto/PropDTO";
import MultiButton from "../Input/MultiButton";
import './DrugTimeSlotWizard.scss'
import Input from "../Input/Input";
import { date2UserDate, stringDate2UserDate, userDate2Date, userDate2stringDate } from "../../services/DateService";
import DaySelector from "../Input/DaySelector";
import TimePickerDD from "../Input/TimePickerDD";
import broadcastService from "../../services/BroadcastService";

enum Status {
  standard,
  new,
  edit,
  timeEdit,
}

export default function DrugTimeSlotWizard (props: {
  drug: DrugDTO,
  item: DrugConfigItem,
  rerender?: () => void,
}) {
  const drug = props.drug
  const item = props.item
  const [rerendercounter, setRerenderCounter] = useState(0)
  function rerender () {
    if (props.rerender) {
      props.rerender()
      return
    }
    setRerenderCounter(rerendercounter + 1)
  }
  const intakeInterval = drug.props.getPropValue1('intakeInterval', 'frequency')

  return <>
    <SlotManager drug={drug} item={item} key1='times_weekday'/>
    {
      intakeInterval !== 'daysofweek' && intakeInterval !== 'interval' &&
      <>
        <div className="SvgCompatibleFontSize">
          Andere Zeiten am Wochenende <Switch drug={drug} key1='times' key2='differentOnWeekend' rerender={rerender} />
        </div>
        {
          props.drug.props.getPropValue1('times', 'differentOnWeekend') === 'active' &&
          <SlotManager drug={drug} item={item} key1='times_weekend'/>
        }
      </>
    }
  </>
}

function Switch (props: {
  drug: DrugDTO,
  key1: string,
  key2: string,
  rerender: () => void,
}) {
  const active = props.drug.props.getPropValue1(props.key1, props.key2) === 'active'
  return <div
    className='w3-show-inline-block pointer'
    onClick={async () => {
      // Switch the property
      await props.drug.props.setProp(props.key1, props.key2, active ? 'inactive' : 'active')
      // Rerender View
      props.rerender()
    }}
  >
    {
      active && <ToggleRight className='SvgActive' />
    }
    {
      !active && <ToggleLeft className='SvgInactive' />
    }
  </div>
}

function SlotManager (props: {
  drug: DrugDTO,
  item: DrugConfigItem,
  key1: string,
}) {
  const drug = props.drug
  const item = props.item
  const [status, setStatus] = useState(Status.standard)
  const [rerendercounter, setRerenderCounter] = useState(0)
  
  function rerender () {
    setRerenderCounter(rerendercounter + 1)
  }
  return <>
    {
      props.drug.props.getProps(props.key1).sort((a,b) => {
        if (a.key2 < b.key2) {
          return -1
        }
        return 1
      }).map((slot, index) => {
        return <SlotDisplay key={`${props.key1}-${slot.key1}-${slot.key2}`} prop={slot} drug={drug} cb={() => {
          rerender()
        }} item={item} />
      })
    }
    {
      status === Status.standard &&
      <button
        className='button-primary'
        onClick={() => {
          setStatus(Status.new)
        }}
      ><Plus /></button>
    }
    {
      status === Status.new &&
      <>
        <h2>Neue Einnahme</h2>
        <SlotEdit
          drug={drug}
          item={item}
          key1={props.key1}
          key2={''}
          cb={() => {
            setStatus(Status.standard)
          }}
          cancel={() => {
            setStatus(Status.standard)
          }}
        />
      </>
    }
  </>
}

function SlotDisplay (props: {
  drug: DrugDTO,
  item: DrugConfigItem,
  prop: PropDTO,
  cb: () => void,
}) {
  const prop = props.prop
  const drug = props.drug
  const item = props.item
  const [status, setStatus] = useState(Status.standard)
  const key1 = props.prop.key1
  const key2 = props.prop.key2
  const intakeInterval = drug.props.getPropValue1('intakeInterval', 'frequency')
  const days = drug.props.getPropValue1(key1 + ':' + key2, 'days') || ''
  const measureUnit = drug.props.getPropValue1('drugInfo', 'measureUnit')
  const interval = drug.props.getPropValue2(key1 + ':' + key2, 'interval_start') || 1
  const intervalStart = stringDate2UserDate(drug.props.getPropValue1(key1 + ':' + key2, 'interval_start') || new Date().toJSON())
  return <div className='h-list-item TimeSlotDisplay w3-card w3-margin-bottom'>
    {
      status === Status.standard &&
      <>
        <div className='w3-padding SlotDisplayTime'>{prop.key2}</div>
        <div className='h-flexGrow'>
          
          {
            intakeInterval === 'daysofweek' &&
            <DaySelector
              days={days}
              className='w3-padding daysselector'
            />
          }
          {
            intakeInterval === 'daily' &&
            <div className='w3-padding SlotDisplayAmount'>Täglich</div>
          }
          {
            intakeInterval === 'interval' &&
            <div className='w3-padding SlotDisplayAmount'>Alle {interval} Tage, beginnend am {intervalStart}</div>
          }
          <div className='w3-padding SlotDisplayAmount'>{prop.value1} {measureUnit}</div>
        </div>
        <button
          className='button-secondary'
          onClick={() => {setStatus(Status.edit)}}
        ><Edit /></button>
        <button
          className='button-secondary'
          onClick={async () => {
            if(prop.key1 && prop.key2 && window.confirm('Diesen Einnahmezeitpunkt wirklich löschen?')) {
              await drug.props.deleteProp(prop.key1 || '', prop.key2 || '')
              props.cb()
            }
          }}
        ><Trash /></button>
      </>
    }
    {
      status === Status.edit &&
      <SlotEdit
        drug={drug}
        item={item}
        key1={prop.key1}
        key2={prop.key2}
        cb={() => {
          setStatus(Status.standard)
          props.cb()
        }}
        cancel={() => {
          setStatus(Status.standard)
        }}
      />
    }
  </div>
}

function dateTimeString2DateString(d: string) {
  if (!d) { return undefined }
  if (d.search(/(.+)T(.+)/) > -1) {
    return d.split('T')[0]
  }
  return d
}

function SlotEdit (props: {
  drug: DrugDTO,
  item: DrugConfigItem,
  key1: string,
  key2: string,
  cb: () => void,
  cancel: () => void,
}) {
  const drug = props.drug
  const prop = props.drug.props.getProp(props.key1, props.key2)
  const [key1, setKey1] = useState(props.key1)
  const [key2, setKey2] = useState(props.key2 || '08:00')
  const [value1, setValue1] = useState(prop?.value1 || '1')
  const [value2, setValue2] = useState(prop?.value2 || '')
  const [status, setStatus] = useState(Status.standard)
  const [interValStart, setIntervalStart] = useState(drug.props.getPropValue1(key1 + ':' + key2, 'interval_start') || new Date().toJSON())
  const [interValValue, setIntervalValue] = useState(drug.props.getPropValue2(key1 + ':' + key2, 'interval_start') || '1')
  const [days, setDays] = useState(drug.props.getPropValue1(key1 + ':' + key2, 'days') || '')
  const intakeInterval = drug.props.getPropValue1('intakeInterval', 'frequency')
  const measureUnit = drug.props.getPropValue1('drugInfo', 'measureUnit')
  let unitInput = null
  if (measureUnit === 'stk') {
    unitInput = <MultiButton
      value={value1}
      values={['1/2', '1','2','3','4','5','6']}
      cb={(val) => {
        setValue1(`${val}`)
      }}
      other='number'
    />
  } else {
    unitInput = <>
      <Input
        value={value1}
        type='number'
        cb={(v: string) => {
          setValue1(v)
        }}
        suffix={measureUnit || ''}
      />
    </>
  }
  let additional = null
  if (intakeInterval === 'interval') {
    // Ask for interval:
    //  - Start
    //  - every x days
    additional = <>
      <h3>Intervall Start</h3>
      <Input
        value={dateTimeString2DateString(interValStart)}
        type='date'
        validate={(v: string) => {
          return date2UserDate(userDate2Date(v))
        }}
        cb={(v: string) => {
          setIntervalStart(date2UserDate(userDate2Date(v)))
        }}
      />
      <h3>Intervall in Tagen</h3>
      <MultiButton
        value={interValValue}
        values={['1','2','3','4','5','6','7']}
        cb={(val) => {
          setIntervalValue(`${val}`)
        }}
        other='number'
      />
    </>
  } else if (intakeInterval === 'daysofweek') {
    // Ask for list of days
    additional = <>
      <h3>Einnahme Tage:</h3>
      <DaySelector
        days={days}
        newDays={(nd: string) => {
          setDays(nd)
        }}
      />
    </>
  }

  // const [status, setStatus] = useState(Status.standard)
  return <>
    <div className='SlotEdit w3-container'>
      <h3>Einnahmezeit:</h3>
      <TimePickerDD
        timeIn={key2}
        cb={(key2: string) => {
          setKey2(key2)
          setStatus(Status.standard)
        }}
      />
      { additional }
      <h3>Menge:</h3>
      { unitInput }
      <hr />
      <button
        className='button-primary w3-margin-bottom'
        onClick={async () => {
          await drug.props.setProp(key1, prop?.key2 || key2, value1, value2, key2)
          await drug.props.setProp(key1 + ':' + key2, 'interval_start', userDate2stringDate(interValStart), interValValue)
          await drug.props.setProp(key1 + ':' + key2, 'days', days, '')
          broadcastService.broadcast('rerenderDrugPlan', {
            id: drug.id,
            pzn: drug.pzn,
            amount: value1,
            time: key2
          })
          props.cb()
        }}
      >Einnahme Speichern</button>
      <button
        className='button-secondary w3-margin-bottom'
        onClick={props.cancel}
      >Abbrechen</button>
    </div>
  </>
}
