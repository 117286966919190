import Com from "../services/ComService"
import { IDrugProfile } from "./DrugProfileDTO"
import DrugProfilesDTO from "./DrugProfilesDTO"
import PropDTO from "./PropDTO"
import { IProp } from "./PropDTO"
import PropsDTO from "./PropsDTO"

export type UserDrug = {
  id: number,
  pzn: string,
  props: PropDTO[]
}

export default class UserDTO {
  id: number = -1
  email: string = ''
  roles: string[] = []
  drugProfiles = new DrugProfilesDTO()
  props: PropsDTO = new PropsDTO()

  constructor(user?: IUser) {
    if (!user) { return }
    console.log('user-->', user)
    this.id = user.id
    this.email = user.email
    this.roles = user.roles
    this.drugProfiles.init(user.drugProfiles)
    this.props = new PropsDTO(user.props, 'user/prop')
    const currentProfile = parseInt(this.props.getPropValue1('profiles', 'current') || '-1', 10 )
    this.drugProfiles.setCurrentProfile(currentProfile)
  }

  public async selfDelete() {
    await Com('DELETE:user', {
      id: this.id
    })
  }

  public async delete() {
    await Com('DELETE:admin/user', {
      id: this.id
    })
  }

  public async setPassword(password: string) {
    const r = await Com('PATCH:admin/user/password', {
      email: this.email,
      wurzel: password
    }, 1) as {status: string}
    return r.status
  }

  public async setCurrentProfile(id: number) {
    this.drugProfiles.setCurrentProfile(id)
    await this.props.setProp('profiles', 'current', `${id}`, '')
  }
}

export interface IUser {
  id: number
  email: string
  roles: string[]
  drugProfiles: IDrugProfile[]
  props: IProp[]
}
