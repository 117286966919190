import { useEffect, useState } from "react"
import { PreferencesDTO } from "../../dto/PreferenceDTO"

enum Status {
  init,
  loading,
  done,
  success,
}

export default function PrefContent(props: {
  key2: string,
  className?: string,
  textonly?: boolean,
}) {
  const [prefs] = useState(new PreferencesDTO())
  const [status, setStatus] = useState(Status.loading)
  useEffect(() => {
    prefs.init().then(() => {
      setStatus(Status.done)
    })
  }, [])
  if (status === Status.done) {
    return <div
      dangerouslySetInnerHTML={{__html: prefs.getPrefV1('content', props.key2)}}
      className={props.className || ''}
    ></div>
  }
  return <></>
}
