import { useEffect, useState } from "react";
import DrugDTO, { IDrug } from "../../dto/DrugDTO";
import Com from "../../services/ComService";
import FormatApoText, { UnformatApoText } from "../../services/FormatApoText";
import Input from "../Input/Input";
import ScannerButton from "../Input/ScannerButton";
import { DosageDisplayName } from "../../dto/DosageForm";
import broadcastService from "../../services/BroadcastService";
import DrugIcon from "../Icons/DrugIcon";
import Spinner from "../Spinner/Spinner";

async function findDrugs(needle: string): Promise <DrugDTO[]> {
  const result = await Com('POST:user/drug/search', {
    needle: UnformatApoText(needle)
  })
  if (!result || (result as IDrug[]).length === undefined) { return [] }
  return (result as IDrug[]).map(r => new DrugDTO(r))
}

let drugs: DrugDTO[] = []

export default function DrugSearch (props: {
  set: (pzn: string) => void,
  hideMainWithoutInput?: boolean,
}) {
  const [needle, setNeedle] = useState('')
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    broadcastService.subscribe('drugSearch', (key, body) => {
      if (key === 'resetSearch') {
        setNeedle('')
      }
    })
  }, [])
  return <>
    <div className='h-search-field-holder'>
      <h3>Produktsuche</h3>
      <Input
        delay={500}
        value={needle}
        cb={async (value) => {
          console.log('input cb', value)
          setLoading(true)
          drugs = await findDrugs(value)
          setNeedle(value)
          setLoading(false)
          // setRerender(rerender + 1)
        }}
        placeholder="PZN oder Name eingeben"
        displayResetButton={true}
      />
    </div>
    {
      (!props.hideMainWithoutInput || needle !== '') &&
      <div className='h-main-content'>
        { loading && <Spinner />
        }
        {
          drugs.length === 0 && !props.hideMainWithoutInput &&
          <ScannerButton />
        }
        {
          drugs.length === 0 && props.hideMainWithoutInput &&
          <div className='neutral-box'>Keine Ergebnisse</div>
        }
        {
          <ResultList
            setNeedle={setNeedle}
            setPzn={props.set}
            drugs={drugs.sort((a,b) => a.langname.localeCompare(b.langname))}
          />
        }
      </div>
    }
  </>
}

export function ResultList(props: {
  setNeedle: (newNeedle: string) => void,
  setPzn: (pzn: string) => void,
  drugs: DrugDTO[],
}) {
  const setNeedle = props.setNeedle
  const setPzn = props.setPzn
  const drugs = props.drugs
  console.log('ResultList', drugs)
  return <>
   {
      drugs.map(d => <Drug
        key={`search-for-drug-result-${d.pzn}`}
        Drug={d}
        onClick={(pzn: string) => {
          setNeedle('')
          setPzn(pzn)
        }}
      />)
    }
  </>
}

function Drug(props: {
    Drug: DrugDTO,
    onClick: (value: string) => void
  }) {
  const drug = props.Drug
  
  return <div
  className='w3-card w3-margin h-list-item h-drugCard pointer'
  onClick={() => {
    broadcastService.broadcast('drawer', {
      pzn: drug.pzn,
      kind: 'druginfo',
      name: drug.getName(),
    })
  }}
>
  <div className='drugChild drugIcon'><DrugIcon drug={drug} /></div>
  <div className='drugChild drugChildMainElement no-round' title={drug.pzn}>
    <div>
      {
        FormatApoText(drug.langname)
      }
    </div>
    
  </div>
</div>
}
