import { NavLink } from "react-router-dom"
import I18n from "../I18n"
import Logout from "../Login/Logout"
import './Menu.scss'
import broadcastService from "../../services/BroadcastService"

const listClass = 'menu-list'
const listItemClass = 'menu-list-item'
const itemClass = 'menu-list-item-button'

type Item = {
  key: string,
  target: string,
  displayName?: string,
  iconClass?: string,
}

type Props = {
  config: {
    items: Item[]
  },
  replace?: [string, string][]
}

const RenderItem = (props: {item: Item, index: number}) => {
  if (props.item.target === 'logout') {
    return <li key={`${props.index}`}>
      <Logout />
    </li>
  }
  return <li
      key={`${props.index}`}
      className={listItemClass}
    >
      <NavLink
        onClick={() => {
          broadcastService.broadcast('plzCloseHamburger')
          broadcastService.broadcast('resetSearch')
        }}
        to={props.item.target}
        className={({isActive}) => isActive ? itemClass + ' menu-item-active' : itemClass + ' menu-item-inactive'}
      >
        <div className={'menu-icon ' + (props.item.iconClass || ' display-no')}></div>
        <div className='menu-long-name'><I18n value={props.item.key} /></div>
        <div className='menu-short-name'><I18n value={props.item.key + '-short'} /></div>
        
      </NavLink>
    </li>
}

const Menu = (props: Props) => {
  const items = props.config.items.map(item => {
    let target = item.target
    if (props.replace) {
      props.replace.forEach(r => {
        target = target.replace(r[0], r[1])
      }) 
    }
    return {...item, ...{
      target: target
    }}
  })
  return <ul
    className={listClass}
  >
    {
      items.map((item, index) => RenderItem({item: item, index: index}))
    }
  </ul>
}

export default Menu
