export default class DrugInteractionDTO {
  pznLeft: string
  pznRight: string
  interaktionstyp: number
  relevanz: string
  text: string[]
  constructor(data: IDrugInteraction) {
    this.pznLeft = data.left.pzn
    this.pznRight = data.right.pzn
    this.relevanz = data.interaction.Relevanz
    this.interaktionstyp = data.interaction.Interaktionstyp
    this.text = data.interaction.Textverweis.map(t => t.Text) // .Text
  }

}

export interface IDrugInteraction {
  interaction: {
    Interaktionstyp: number,
    Relevanz: string,
    Textverweis: {
      Text: string,
    }[]
  },
  left:{
    pzn: string
  },
  right:{
    pzn: string
  },
}
