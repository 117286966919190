import { PlusCircle } from "react-feather";
import { NavLink } from "react-router-dom";
import './Button.scss'

export default function BPlus(props: {
  target?: string,
  onClick?: () => void,
}) {
  const Icon = <PlusCircle />
  if (props.target) {
    return <NavLink
    to={props.target}
    className={`h-standardButton`}
  >
    { Icon }
  </NavLink>
  }
  return <button
    className='h-standardButton'
    onClick={() => {
      if (props.onClick) {
        props.onClick()
      }
    }}
  >
    { Icon }
  </button>
}
