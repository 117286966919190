export default function GetlastIdUrl(toInt?: boolean) {
  const scope = /\/([0-9]+)$/
  const match = window.location.href.match(scope)
  if (match) {
    if (toInt) { return parseInt(match[1], 10) }
    return match[1]
  }
  if (toInt) { return -1 }
  return ''
}
