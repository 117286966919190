import { useEffect, useState } from "react";
import PrefContent from "../Preference/PrefContent";
import { PreferencesDTO } from "../../dto/PreferenceDTO";

enum Status {
  init,
  loading,
  done,
  success,
}

export default function Legal(props: {

}) {
  const [prefs] = useState(new PreferencesDTO())
  const [status, setStatus] = useState(Status.loading)
  useEffect(() => {
    prefs.init().then(() => {
      setStatus(Status.done)
    })
  }, [])
  if (status === Status.loading) {
    return <></>
  }
  return <div className='pointer w3-container info-box w3-margin'>
    <details>
      <summary
        dangerouslySetInnerHTML={{__html: prefs.getPrefV1('content', 'medikamentencheckDisclaimerTitle').replace(/<[^>]+>/g, '')}}
      ></summary>
      <PrefContent key2='medikamentencheckDisclaimerContent' />
    </details>
  </div>
}