import loginService from '../../services/LoginService'

export default function Logout() {
  return <button
    className='w3-button logout'
    onClick={() => {
      loginService.Logout()
    }}
  >Logout</button>
}
