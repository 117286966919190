import { useEffect, useState } from "react";
import DrugDTO from "../../dto/DrugDTO";
import UserDTO from "../../dto/UserDTO";
import GetlastIdUrl from "../../services/GetLastIdUrl";
import userService from "../../services/UserService";
import BCancel from "../Buttons/BCancel";
import BLeft from "../Buttons/BLeft";
import { ResultList } from "../Drugs/DrugSearch";
import Spinner from "../Spinner/Spinner";
import ScannerButton from "./ScannerButton";

enum Status {
  loading,
  standard,
}

let scanSuccessDrugs: DrugDTO[] = []

export default function ScanScuccess() {
  console.log('userService', userService.user)
  const [status, setStatus] = useState(Status.loading)
  const pzn = GetlastIdUrl()
  const [user, setUser] = useState(new UserDTO())
  
  useEffect(() => {
    userService.get().then(async (u) => {
      if (!u) { return }
      setUser(u)
      scanSuccessDrugs = await u.drugProfiles.getDrugsInfoFromServer(`${pzn}`)
      setStatus(Status.standard)
    })
  }, [])
  return <>
    <div className='h-header '>
      <div>
        <BLeft
          target='/user/medikamentensuche'
        />
      </div>
      <div>Ergebnis</div>
      <div>
        <BCancel
          target='/user/medikamente'
        />
      </div>
    </div>
    <div className='h-main-content'>
        {
          status === Status.loading &&
          <Spinner />
        }
        {
          status === Status.standard &&
          <ResultList
            setNeedle={(bla: string) => {

            }}
            setPzn={async (pzn: string) => {
              console.log('setNewNeedle')
              await user.drugProfiles.addPzn(pzn)
              window.location.href = '/user/medikamente'
            }}
            drugs={scanSuccessDrugs}
          />
        }
        <div className='w3-container'>Ergebnis auswählen oder erneut scannen</div>
      <ScannerButton />
    </div>
  </>
}