import { useEffect, useState } from "react";
import DrugDTO, { DrugConfig, DrugConfigItem, ItemKind } from "../../dto/DrugDTO";
import GetlastIdUrl from "../../services/GetLastIdUrl";
import i18n from "../../services/i18n";
import userService from "../../services/UserService";
import BCancel from "../Buttons/BCancel";
import BLeft from "../Buttons/BLeft";
import BMain from "../Buttons/BMain";
import './DrugConfigWizard.scss';
import DrugTimeSlotWizard from "./DrugTimeSlotWizard";
import MainMenu from "../User/MainMenu";
import DropDown from "../Input/DropDown";
import Spinner from "../Spinner/Spinner";
import { Dosage2Unit } from "../../dto/DosageForm";

enum Status {
  loading,
  standard,
  notFound,
  other,
}
export default function DrugConfigWizard(props: {

}) {
  const [page, setPage] = useState(0)
  const [status, setStatus] = useState(Status.loading)
  // const [user, setUser] = useState(new UserDTO())
  const [drug, setDrug] = useState(new DrugDTO())
  useEffect(() => {
    userService.get().then((u) => {
      if (!u) { return }
      // setUser(u)
      setStatus(Status.standard)
      const drugId = GetlastIdUrl(true) as number
      const drug = u.drugProfiles.getUserdrugById(drugId)
      if (drug) {
        setDrug(drug)
      } else {
        setStatus(Status.notFound)
      }
    })
  }, [])
  return <>
    <div className='h-header '>
      <div>
        <BLeft
          onClick={() => {
            if (page === 0) {
              window.location.href = '/user/medikamente'
            } else {
              setPage(page - 1)
            }
          }}
        />
      </div>
      <div>Medikamenteneinstellungen - {drug.getName()}</div>
      <div>
        <BCancel
          target='/user/medikamente'
          displayValue="Fertig"
        />
      </div>
    </div>
    <div className='page-headline'>
      <h2>{drug.getName()}</h2>
    </div>
    {
      status === Status.notFound &&
      <div className='alert-box'>Medikament nicht gefunden</div>
    }
    <RenderWizardPage page={page} drug={drug} goToNextPage={() => {
      setPage(page + 1)
    }}/>
    <MainMenu className='hideOnMobile' />
  </>
}

function RenderWizardPage(props: {
  page: number,
  drug: DrugDTO,
  goToNextPage: () => void,
}) {
  const pageId = props.page
  const drug = props.drug
  return <>
    <div className='h-main-content'>
      {
        DrugConfig[pageId].items.map((c, index) => {
          return <div
            key={`RenderWizardElement-${index}`}
            className=''
          >
            <RenderWizardElement drug={drug} item={c} done={
              () => {
                if (NextPageAvailable(pageId)) {
                  props.goToNextPage()
                }
              }
            } />
          </div>
        })
      }
    </div>
    {
      NextPageAvailable(pageId) &&
      <BMain onClick={props.goToNextPage} content={`Nächste Seite`} />
    }
    {
      !NextPageAvailable(pageId) &&
      <BMain onClick={() => {
        window.location.href = '/user/medikamente'
      }} content={`Fertig`} />
    }
  </>
}

function RenderWizardElement(props: {
  drug: DrugDTO,
  item: DrugConfigItem,
  done: () => void,
}) {
  const item = props.item
  const drug = props.drug
  switch(props.item.kind) {
    case ItemKind.explainText:
      return <div className='w3-container text-box-main'>{item.displayContent}</div>
    case ItemKind.multiButtonRadio:
      return <div className="w3-container">
        <RenderMultiButtonRadio
          item={item}
          drug={drug}
          done={props.done}
        />
      </div>
    case ItemKind.drugTimeSlots:
      return <div className="w3-container">
        <DrugTimeSlotWizard
          item={item}
          drug={drug}
        />
      </div>
  }
  return <div>
    - Empty Element -
  </div>
}

function RenderOverviewElement(props: {
  drug: DrugDTO,
  item: DrugConfigItem,
  rerender: () => void,
}) {
  const item = props.item
  const drug = props.drug
  switch(props.item.kind) {
    case ItemKind.explainText:
      return <div className='text-box'><b>{item.displayOverview || item.displayContent}</b></div>
    case ItemKind.multiButtonRadio:
      return <RenderDropDown
        item={item}
        drug={drug}
        rerender={() => {props.rerender()}}
      />
    case ItemKind.drugTimeSlots:
      return <DrugTimeSlotWizard
        item={item}
        drug={drug}
      />
  }
  return <div>
    - Empty Element -
  </div>
}

function NextPageAvailable(currentPage: number): boolean {
  return (currentPage + 1 < DrugConfig.length)
}

function RenderMultiButtonRadio(props: {
  drug: DrugDTO,
  item: DrugConfigItem,
  done: () => void,
}) {
  const item = props.item
  const drug = props.drug
  const [rerender, setRerender] = useState(0)
  const [status, setStatus] = useState(Status.standard)

  const currentValue = drug.props.getPropValue1(item.key1, item.key2) || ((item.key2 === 'measureUnit' && drug.dosageForm) ? Dosage2Unit(drug.dosageForm) : '')

  const [value, setValue] = useState(currentValue)
  let foundValue = false
  return <div>
    {
      (item.options || []).map((o, index) => {
        const active = (o.value === (value || currentValue))
        if (active) {
          foundValue = true
        }
        const className = `MultiButton MultiButton-${active ? 'active' : 'inactive'}`
        return <button
          className={className}
          key={`${item.key1}-${item.key2}-${index}`}
          onClick={async () => {
            setValue(o.value)
            await drug.props.setProp(item.key1, item.key2, o.value)
            setRerender(rerender + 1)
            props.done()
          }}
        >
          {i18n.get(o.displayContent)}
        </button>
      })
    }
    {
      item.other && <>
        <button
          className={`MultiButton MultiButton-${(!foundValue || status === Status.other) ? 'active' : 'inactive'}`}
          onClick={() => {
            setValue('')
            setStatus(Status.other)
          }}
        >
          {item.other}
        </button>
        {
          (!foundValue || status === Status.other) &&
          <div>
            <input
              placeholder={`Eigenen Wert hier eintragen`}
              className='w3-center w3-block w3-margin-bottom'
              onChange={async (event) => {
                setValue(event.target.value)
                await drug.props.setProp(item.key1, item.key2, event.target.value)
              }}
              value={value}
            />
          </div>
        }
      </>
    }
  </div>
}

function RenderDropDown(props: {
  drug: DrugDTO,
  item: DrugConfigItem,
  rerender: () => void,
}) {
  const item = props.item
  const drug = props.drug
  const [rerender, setRerender] = useState(0)
  const currentValue = drug.props.getPropValue1(item.key1, item.key2)
  return <div>
    <DropDown
      value={currentValue || ''}
      cb={async (value) => {
        await drug.props.setProp(item.key1, item.key2, (value || '') + '')
          setRerender(rerender + 1)
          props.rerender()
      }}
      options={(item.options || []).map(i => {
        return {
          displayValue: i.displayContent,
          value: i.value,
        }
      })}
      other={item.other}
    />
  </div>
}

export function DrugConfigOverview(props: {
  drugId: number,
}) {
  const [drug, setDrug] = useState(new DrugDTO())
  const [status, setStatus] = useState(Status.loading)
  const drugId = props.drugId
  useEffect(() => {
    userService.get().then((u) => {
      if (!u) { return }
      const drug = u.drugProfiles.getUserdrugById(drugId)
      if (drug) {
        setDrug(drug)
        setStatus(Status.standard)
      } else {
        setStatus(Status.notFound)
      }
    })
  }, [])
  if (status === Status.loading) {
    return <Spinner />
  }
  return <div>
    {
      DrugConfig.map((cpage, pindex) => {
        return <div
          key={`cpage-${pindex}`}
          className=''
        >
          {
            cpage.items.map((citem, iindex) => {
              return <div
                key={`citem-${iindex}`}
                className=''
              >
                <RenderOverviewElement
                  drug={drug}
                  item={citem}
                  rerender={() => {
                    setStatus(Status.loading)
                    setTimeout(() => {
                      setStatus(Status.standard)
                    }, 500)
                  }}
                />
              </div>
            })
          }
        </div>
      })
    }
  </div>
}