import { useEffect, useState } from 'react'
import './Drawer.scss'
import broadcastService from '../../services/BroadcastService'
import ThingHolder from '../../services/ThingHolder'
import Com from '../../services/ComService'
import Spinner from '../Spinner/Spinner'
import { Calendar, Check, X } from 'react-feather'
import FormatApoText from '../../services/FormatApoText'
import UserDTO from '../../dto/UserDTO'
import userService from '../../services/UserService'
import DrugIcon from '../Icons/DrugIcon'
import { DrugConfigOverview } from '../Drugs/DrugConfigWizard'

enum Status {
  hidden,
  loading,
  showing,
  showLeaflet,
  askForAddingTimetable,
}

enum Tab {
  description,
  intake,
}

export default function Drawer(props: {}) {
  const [state, setState] = useState(Status.hidden)
  const [dataManager] = useState(new ThingHolder())
  const [headline, setHeadline] = useState('')
  const [user, setUser] = useState(new UserDTO())
  const [tab, setTab] = useState(0)
  const [drugId, setDrugId] = useState(-1)
  const [pzn, setPzn] = useState('')
  useEffect(() => {
    broadcastService.subscribe('drawer', (key, body) => {
      if (key !== 'drawer') {
        return
      }
      userService.get().then((u) => {
        if (!u) { return }
          setUser(u)
          if (body && body.kind === 'druginfo') {
            setState(Status.loading)
            setHeadline(body.name)
            setDrugId(body.drugId || -1)
            setPzn(body.pzn)
            Com('POST:drug/leafletByPzn', {
              pzn: body.pzn
            }).then((data) => {
              dataManager.set(data)
              console.log('Drawer Data', data)
              setState(Status.showLeaflet)
            })
          }
      })
    })
  }, [])
  const drug = user.drugProfiles.getUserdrugById(drugId)
  const data = dataManager.get()
  function makeHeader() {
      return <div className='d-Header'>
          {
            drug !== null &&
            <div className='d-Icon'><DrugIcon drug={drug} /></div>
          }
          <h2>{headline}</h2>
          <div className='d-subinfo'>Hersteller</div>
        </div>
  }
  return <div className={`RightDrawer ${state === Status.hidden ? 'hideDrawer' : 'showDrawer'}`}>
    <div className='h-flex'>
      <div></div>
      <X
        onClick={() => {
          if (state === Status.askForAddingTimetable) {
            window.location.href = '/user/medikamente'
            return
          }
          setState(Status.hidden)
        }}
        className='pointer w3-margin'
      />
    </div>
    {
      state === Status.loading &&
      <Spinner />
    }
    {
      state === Status.showLeaflet &&
      <>
        {makeHeader()}
        <div className='d-Menu'>
          <div className={`d-Menuitem ${tab === Tab.description ? 'd-Menu-active' : ''}`} onClick={() => {
            setTab(Tab.description)
          }}>
            Beschreibung
          </div>
          {
            drugId !== -1 &&
            <div className={`d-Menuitem ${tab === Tab.intake ? 'd-Menu-active' : ''}`} onClick={() => {
              setTab(Tab.intake)
            }}>
              Einnahme
            </div>
          }
          {
            drugId === -1 &&
            <div
              className={`h-flex d-Menuitem ${tab === Tab.intake ? 'd-Menu-active' : ''}`}
              onClick={async () => {
                const userdrug = await user.drugProfiles.addPzn(pzn) as {id: number}
                setDrugId(userdrug.id)
                setState(Status.askForAddingTimetable)
              }}>
              <Check /> Eintragen
            </div>
          }
        </div>
        {

        }
        {
          tab === Tab.description &&
          <div className='d-Content'>
            <p dangerouslySetInnerHTML={{__html: FormatApoText(data.Anwendungshinweise)}}></p>
            <p dangerouslySetInnerHTML={{__html: FormatApoText(data.Gegenanzeigen)}}></p>
            <p dangerouslySetInnerHTML={{__html: FormatApoText(data.Nebenwirkungen)}}></p>
            <p dangerouslySetInnerHTML={{__html: FormatApoText(data.WichtigeHinweise)}}></p>
            <p dangerouslySetInnerHTML={{__html: FormatApoText(data.Wirkungsweise)}}></p>
          </div>
        }
        {
          tab === Tab.intake &&
          <div className='d-Content'>
            <DrugConfigOverview drugId={drugId} />
          </div>
        }
      </>
    }
    {
      state === Status.askForAddingTimetable &&
      <>
        {makeHeader()}
        <div className='d-Menu h-flex h-flex-wrap h-flex-gap'>
          <a
            href={'/user/medikament/einstellungen/' + drugId}
            className={`d-Menuitem no-text-decoration h-flex`}
          >
            <Calendar /> Einnahmezeitpunkte hinzufügen
          </a>
          <a
            href={'/user/medikamente'}
            className={`w3-button d-Menuitem no-text-decoration d-Menu-active h-flex h-flex-no-shrink`}
          >
            <Check /> Fertig
          </a>
        </div>
      </>
    }
  </div>
}
