// Derived from database:
import dosage2unit from '../config/dosage2unit.json'
export const DosageForms = [
  {displayContent: "Keine Angabe", value: "", count: 354465},
  {displayContent: "Ätherisches Öl", value: "AEO"},
  {displayContent: "Ampullen", value: "AMP"},
  {displayContent: "Ampullenpaare", value: "APA"},
  {displayContent: "Augen- und Nasensalbe", value: "ASN"},
  {displayContent: "Augen- und Ohrensalbe", value: "ASO"},
  {displayContent: "Augen- und Ohrentropfen", value: "ATO"},
  {displayContent: "Augenbad", value: "AUB"},
  {displayContent: "Augencreme", value: "AUC"},
  {displayContent: "Augengel", value: "AUG"},
  {displayContent: "Augensalbe", value: "AUS"},
  {displayContent: "Augentropfen", value: "ATR"},
  {displayContent: "Bad", value: "BAD"},
  {displayContent: "Balsam", value: "BAL"},
  {displayContent: "Bandage", value: "BAN"},
  {displayContent: "Basisplatte", value: "BPL"},
  {displayContent: "Beutel", value: "BEU"},
  {displayContent: "Beutel mit retardierten Filmtabletten", value: "FRB"},
  {displayContent: "Binden", value: "BIN"},
  {displayContent: "Bonbons", value: "BON"},
  {displayContent: "Brausetabletten", value: "BTA"},
  {displayContent: "Brei", value: "BRE"},
  {displayContent: "Creme", value: "CRE"},
  {displayContent: "Deo-Spray", value: "XDS"},
  {displayContent: "Depot-Injektionssuspension", value: "DIS"},
  {displayContent: "Digitale Gesundheitsanwendungen", value: "DIG"},
  {displayContent: "Dilution", value: "DIL"},
  {displayContent: "Dosieraerosol", value: "DOS"},
  {displayContent: "Dosierschaum", value: "DSC"},
  {displayContent: "Dosierspray", value: "DSS"},
  {displayContent: "Dragees", value: "DRA"},
  {displayContent: "Dragees in Kalenderpackung", value: "DKA"},
  {displayContent: "Dragees magensaftresistent", value: "DRM"},
  {displayContent: "Durchstechflaschen", value: "DFL"},
  {displayContent: "Duschgel", value: "XDG"},
  {displayContent: "Einreibung", value: "EIN"},
  {displayContent: "Einzeldosis-Pipetten", value: "EDP"},
  {displayContent: "Elektroden", value: "ELE"},
  {displayContent: "Elixier", value: "ELI"},
  {displayContent: "Emulsion", value: "EMU"},
  {displayContent: "Erwachsenen-Suppositorien", value: "ESU"},
  {displayContent: "Essenz", value: "ESS"},
  {displayContent: "Extrakt", value: "EXT"},
  {displayContent: "Fertigspritzen", value: "FER"},
  {displayContent: "Festiger", value: "XFE"},
  {displayContent: "Fettsalbe", value: "FET"},
  {displayContent: "Filmdragees", value: "FDA"},
  {displayContent: "Filmtabletten", value: "FTA"},
  {displayContent: "Filmtabletten magensaftresistent", value: "FMR"},
  {displayContent: "Filterbeutel", value: "FBE"},
  {displayContent: "Flasche", value: "FLA"},
  {displayContent: "Flüssig", value: "FLU"},
  {displayContent: "Flüssigkeit zum Einnehmen", value: "FLE"},
  {displayContent: "Flüssigseife", value: "FSE"},
  {displayContent: "Folie", value: "FOL"},
  {displayContent: "Franzbranntwein", value: "FBW"},
  {displayContent: "Gas und Lösungsmittel zur Herstellung einer Injektions-/Infusions-dispersion", value: "GLI"},
  {displayContent: "Gel", value: "GEL"},
  {displayContent: "Gelplatte", value: "GPA"},
  {displayContent: "Gesichtsmaske", value: "XGM"},
  {displayContent: "Globuli", value: "GLO"},
  {displayContent: "Granulat", value: "GRA"},
  {displayContent: "Granulat zur Entnahme aus Kapseln", value: "GEK"},
  {displayContent: "Granulat zur Herstellung einer Suspension zum Einnehmen", value: "GSE"},
  {displayContent: "Gurgellösung", value: "GUL"},
  {displayContent: "Haarspülung", value: "XHS"},
  {displayContent: "Halsband", value: "XHA"},
  {displayContent: "Handschuhe HKP Hartkapseln KMP Hartkapseln mit magensaftresistent überzogenen Pellets HPI Hartkapseln mit Pulver zur Inhalation HVW Hartkapseln mit veränderter Wirkstofffreisetzung IMP Implantat IFA Infusionsampullen IFB Infusionsbeutel IFD Infusionsdispersion IFF Infusionsflaschen INF Infusionslösung IFK Infusionslösungskonzentrat IFS Infusionsset INH Inhalat IHA Inhalationsampullen IKA Inhalationskapseln INL Inhalationslösung IHP Inhalationspulver IFL Injektionsflaschen INI Injektions-, Infusionsflaschen IIL Injektions-, Infusionslösung IIE Injektions- oder Infusionslösung oder Lösung zum Einnehmen ILO Injektionslösung PEN Injektionslösung in einem Fertigpen IFE Injektionslösung in einer Fertigspritze", value: "HAS"},
  {displayContent: "Injektionslösung zur intramuskulären Anwendung", value: "IIM"},
  {displayContent: "Injektionssuspension INS Instant-Tee IST Instillation IUP Intrauterinpessar KAN Kanülen KAP Kapseln KMR Kapseln magensaftresistent", value: "ISU"},
  {displayContent: "Kapseln magensaftresistent", value: "KMR"},
  {displayContent: "Katheter", value: "KAT"},
  {displayContent: "Kaudragees", value: "KDA"},
  {displayContent: "Kaugummi", value: "KGU"},
  {displayContent: "Kautabletten", value: "KTA"},
  {displayContent: "Kegel", value: "KEG"},
  {displayContent: "Kerne", value: "KER"},
  {displayContent: "Kinder- und Säuglings-Suppositorien", value: "KSS"},
  {displayContent: "Kinder-Suppositorien", value: "KSU"},
  {displayContent: "Kleinkinder-Suppositorien", value: "KKS"},
  {displayContent: "Klistier-Tabletten", value: "KLT"},
  {displayContent: "Klistiere", value: "KLI"},
  {displayContent: "Körperpflege", value: "XPK"},
  {displayContent: "Kombipackung", value: "KPG"},
  {displayContent: "Kompressen", value: "KOM"},
  {displayContent: "Kondome", value: "KOD"},
  {displayContent: "Konzentrat", value: "KON"},
  {displayContent: "Konzentrat zur Herstellung einer Infusionsdispersion", value: "KID"},
  {displayContent: "Konzentrat zur Herstellung einer Injektions- oder Infusionslösung", value: "KII"},
  {displayContent: "Kristallsuspension", value: "KRI"},
  {displayContent: "Lacktabletten", value: "LTA"},
  {displayContent: "Lanzetten", value: "LAN"},
  {displayContent: "Liquidum", value: "LIQ"},
  {displayContent: "Lösung", value: "LOE"},
  {displayContent: "Lösung für einen Vernebler", value: "LOV"},
  {displayContent: "Lösung zum Einnehmen", value: "LSE"},
  {displayContent: "Lösung zur Injektion, Infusion und Inhalation", value: "LII"},
  {displayContent: "Lotion", value: "LOT"},
  {displayContent: "Lutschpastillen", value: "LUP"},
  {displayContent: "Lutschtabletten", value: "LUT"},
  {displayContent: "Magensaftresistente Hartkapseln", value: "HKM"},
  {displayContent: "Magensaftresistente Pellets", value: "MRP"},
  {displayContent: "Magensaftresistente Weichkapseln", value: "WKM"},
  {displayContent: "Magensaftresistentes Retardgranulat MTA Manteltabletten MIL Milch MIS Mischung MIX Mixtur MUW Mundwasser XNC Nachtcreme NDS Nasendosierspray NAG Nasengel NAO Nasenöl NSA Nasensalbe NAS Nasenspray NTR Nasentropfen OCU Ocusert OEL Öl OHT Ohrentropfen OVU Ovula PAM Packungsmasse PST Paste PAS Pastillen PEL Pellets PER Perlen PLG Perlongetten PFL Pflaster PFT Pflaster transdermal PRS Presslinge PUD Puder PUL Pulver PKI Pulver für ein Konzentrat zur Herstellung einer Infusionslösung Veröffentlichung zum 01.07.2021", value: "MRG"},
  {displayContent: "Pulver für ein Konzentrat zur Herstellung einer Infusions-lösung, Pulver zur Herstellung einer Lösung zum Einnehmen", value: "PIE"},
  {displayContent: "Pulver und Lösungsmittel für ein Konzentrat zur Herstellung einer Infusionslösung", value: "PLK"},
  {displayContent: "Pulver und Lösungsmittel zur Herstellung einer Infusionslösung", value: "PLF"},
  {displayContent: "Pulver und Lösungsmittel zur Herstellung einer Injektions- bzw. Infusionslösung", value: "PLH"},
  {displayContent: "Pulver und Lösungsmittel zur Herstellung einer Injektionslösung", value: "PLI"},
  {displayContent: "Pulver und Lösungsmittel zur Herstellung einer Injektionssuspension", value: "PLS"},
  {displayContent: "Pulver und Lösungsmittel zur Herstellung einer Lösung zur intravesikalen Anwendung", value: "PLV"},
  {displayContent: "Pulver zur Herstellung einer Infusionslösung", value: "PIF"},
  {displayContent: "Pulver zur Herstellung eines Infusionslösungskonzentrates", value: "PIK"},
  {displayContent: "Pulver zur Herstellung einer Infusionssuspension", value: "PIS"},
  {displayContent: "Pulver zur Herstellung einer Injektionslösung", value: "PIJ"},
  {displayContent: "Pulver zur Herstellung einer Injektions- oder Infusionslösung", value: "PII"},
  {displayContent: "Pulver zur Herstellung einer Injektions-, Infusions- oder Inhalationslösung", value: "PHI"},
  {displayContent: "Pulver zur Herstellung einer Injektions- bzw. Infusionslösung oder einer Lösung zur intravesikalen Anwendung", value: "PIV"},
  {displayContent: "Pulver zur Herstellung einer Injektions- bzw. Infusionslösung oder Pulver und Lösungsmittel zur Herstellung einer Lösung zur intravesikalen Anwendung", value: "PHV"},
  {displayContent: "Pulver zur Herstellung einer Lösung zum Einnehmen", value: "PLE"},
  {displayContent: "Pulver zur Herstellung einer Suspension zum Einnehmen", value: "PSE"},
  {displayContent: "Pumplösung", value: "PPL"},
  {displayContent: "Rektalkapseln", value: "RKA"},
  {displayContent: "Rektalschaum", value: "RSC"},
  {displayContent: "Rektalsuspension", value: "RSU"},
  {displayContent: "Retard-Dragees", value: "RED"},
  {displayContent: "Retard-Granulat", value: "RGR"},
  {displayContent: "Retard-Kapseln", value: "REK"},
  {displayContent: "Retard-Tabletten", value: "RET"},
  {displayContent: "Retard-überzogene Tabletten", value: "RUT"},
  {displayContent: "Retardmikrokapseln und Suspensionsmittel", value: "RMS"},
  {displayContent: "Säuglings-Suppositorien", value: "SSU"},
  {displayContent: "Saft", value: "SAF"},
  {displayContent: "Salbe SAM Salbe zur Anwendung in der Mundhöhle SLZ Salz SCH Schaum SMF Schmelzfilm SMT Schmelztabletten SWA Schwämme SEI Seife SHA Shampoo SIR Sirup SPR Spray SPA Spritzampullen SRI Spritzen SPF Sprühflasche SPL Spüllösung STB Stäbchen STA Stechampullen STI Stifte STR Streifen SUL Sublingualspray, Lösung SUT Sublingualtabletten SUB Substanz SUP Suppositorien SMU Suppositorien mit Mulleinlage SUS Suspension SUV Suspension für einen Vernebler SUE Suspension zum Einnehmen TAB Tabletten TKA Tabletten in Kalenderpackung TMR Tabletten magensaftresistent TVW Tablette mit veränderter Wirkstofffreisetzung", value: "SAL"},
  {displayContent: "Tablette zur Herstellung einer Lösung zum Einnehmen", value: "TLE"},
  {displayContent: "Tablette zur Herstellung einer Suspension zum Einnehmen", value: "TSE"},
  {displayContent: "Tabletten zur Herstellung einer Suspension zum Einnehmen für einen Dosierspender", value: "TSD"},
  {displayContent: "Täfelchen XTC Tagescreme TPN Tampon TPO Tamponaden TEE Tee TES Test TST Teststäbchen TTR Teststreifen TIN Tinktur TON Tonikum SPT Transdermales Spray TSY Transdermales System TRA Trinkampullen TRT Trinktabletten TRI Trituration TAM Trockenampullen TSA Trockensaft TRS Trockensubstanz mit Lösungsmittel TSS Trockensubstanz ohne Lösungsmittel TRO Tropfen TEI Tropfen zum Einnehmen TUB Tube TUE Tücher TUP Tupfer UTA Überzogene Tabletten VCR Vaginalcreme VGE Vaginalgel VKA Vaginalkapseln VAL Vaginallösung VOV Vaginalovula", value: "TAE"},
  {displayContent: "Vaginalring", value: "VAR"},
  {displayContent: "Vaginalstäbchen", value: "VST"},
  {displayContent: "Vaginalsuppositorien", value: "VSU"},
  {displayContent: "Vaginaltabletten", value: "VTA"},
  {displayContent: "Verband", value: "VER"},
  {displayContent: "Vlies", value: "VLI"},
  {displayContent: "Watte", value: "WAT"},
  {displayContent: "Weichkapseln", value: "WKA"},
  {displayContent: "Wirkstoffhaltiger Nagellack", value: "NAW"},
  {displayContent: "Würfel", value: "WUE"},
  {displayContent: "Wundgaze", value: "WGA"},
  {displayContent: "Zahnbürste", value: "ZBU"},
  {displayContent: "Zahncreme", value: "ZCR"},
  {displayContent: "Zahngel", value: "ZGE"},
  {displayContent: "Zahnpasta", value: "ZPA"},
  {displayContent: "Zerbeisskapseln", value: "ZKA"},
  {displayContent: "Zylinderampullen", value: "ZAM"},
]

export function DosageDisplayName(key: string) {
  const DosageForm = DosageForms.find(v => v.value === key)
  if (DosageForm) {
    return DosageForm.displayContent || 'Keine Angabe'
  }
  return 'Keine Angabe'
}

export function Dosage2Unit(dosage: string): string {
  const result = dosage2unit.find(u => u[0] === dosage)
  if (result) {
    return result[1]
  }
  return ''
}
