import broadcastService from "../../services/BroadcastService"
import Com from "../../services/ComService"

const handleFileUpload = async (file: File, path: string) => {
  console.log('FILE STATUS', file)
  let formData = new FormData()
  console.log('addAvatarPicture', file)
  formData.append('FileName', file)
  await Com('POST:' + path, formData)
  // const target = path
  /*
  const rawResponse = await fetch('http://localhost:3001/user/picture', {
      method: 'POST',
      body: formData,
    }
  )
  const result = await rawResponse.json()
  */
}

export default function PictureUploader(props: {
  cb: () => void,
  path: string
}) {
  return <input
    className='w3-margin w3-padding'
    type='file'
    onChange={async (event) => {
      if (event.target?.files && event.target?.files.length > 0) {
        await handleFileUpload(event.target.files[0], props.path)
        console.log('com done')
        props.cb()
        broadcastService.broadcast('fileUploadDone')
      }
    }}
  />
}