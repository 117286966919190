import { useState, useRef } from 'react'
import { X } from 'react-feather'

const Input = (props: {
  value?: string,
  autofocus?: boolean,
  className?: string,
  delay?: number,
  placeholder?: string,
  type?: string,
  displayResetButton?: boolean,
  validate?: (v: string) => string,
  onEnter?: (value: string) => void,
  cb: (value: string) => void,
  suffix?: string,
}) => {
  const [value, setValue] = useState(props.value || '')
  const refTimer = useRef<any | null>(null)
  const change = (v: string) => {
    if (refTimer.current !== null) {
      clearTimeout(refTimer.current)
      refTimer.current = null
    }
    refTimer.current = setTimeout(() => {
      props.cb(v)
    }, props.delay || 100)
  }
  return <div className='theSearchField'>
    <input
      value={value}
      className={`${props.className || ''}`}
      onChange={(event) => {
        setValue(event.target.value)
        change(event.target.value)
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          if (props.onEnter) {
            if (props.validate) { setValue(props.validate(value)) }
            props.onEnter(value)
          } else {
            props.cb(value)
          }
        }
      }}
      onBlur={() => {
        if (props.validate) { setValue(props.validate(value)) }
        props.cb(value)
      }}
      autoFocus={!!props.autofocus}
      placeholder={props.placeholder || ''}
      type={props.type || 'text'}
    ></input>
    {
      props.suffix &&
      <span className='w3-margin-left'>{props.suffix}</span>
    }
    {
      props.displayResetButton &&
      <X
        className='cursor reset-button'
        onClick={() => {
          setValue('')
          change('')
          if (props.onEnter) { props.onEnter('') } else { props.cb('') }
        }}
      />
    }
  </div>
}
export default Input

export function OkInput (props: {
  value?: string,
  autofocus?: boolean,
  className?: string,
  delay?: number,
  placeholder?: string,
  type?: string,
  validate?: (v: string) => string,
  onEnter?: (value: string) => void,
  cb: (value: string) => void
}) {
  const [value, setValue] = useState(props.value)
  return <>
    <Input
      value={value}
      autofocus={props.autofocus}
      className={props.className}
      delay={props.delay}
      placeholder={props.placeholder}
      type={props.type}
      validate={props.validate}
      onEnter={props.onEnter}
      cb={(value) => {
        setValue(value)
      }}
    />
    {
      value !== props.value &&
      <>
        <button
          onClick={() => {
            props.cb(value || '')
          }}
        >Speichern</button>
        <button
          onClick={() => {
            setValue(props.value)
          }}
        >Abbrechen</button>
      </>
    }
  </>
}

// Timeout inspired by: // https://www.kindacode.com/article/react-typescript-using-settimeout-with-hooks/
