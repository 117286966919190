import Com from "../services/ComService"
import DrugInteractionDTO, { IDrugInteraction } from "./DrugInteractionDTO"

export default class DrugInteractionsDTO {
  currentVersion: string = ''
  interactions: DrugInteractionDTO[] = []

  async getFromServer(pzns: string) {
    if (this.currentVersion === pzns) { return }
    const result = await Com('POST:user/drug/interactions', {
      pzns: pzns,
    })
    this.currentVersion = pzns
    this.interactions = (result as {interactions: IDrugInteraction[]}).interactions.map(i => new DrugInteractionDTO(i))
  }

  get() {
    return this.interactions.sort((a, b) => (a.relevanz < b.relevanz) ? -1 : 1)
  }
}
