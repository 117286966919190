import { ReactElement, useEffect, useState } from "react";
import userService from "../../services/UserService";
import UserDTO from "../../dto/UserDTO";
// import Com from "../../services/ComService";
import DrugInteractionDTO from "../../dto/DrugInteractionDTO";
import FormatApoText from "../../services/FormatApoText";
import { NavLink } from "react-router-dom";
import Spinner from "../Spinner/Spinner";

enum Status {
  loading,
  standard,
  search,
}

export default function DrugInteraction(props: {
  teaserOnly?: boolean,
  teaserTarget?: string,
}) {
  const [status, setStatus] = useState(Status.loading)
  const [user, setUser] = useState(new UserDTO())
  useEffect(() => {
    userService.get().then(async (u) => {
      if (!u) { return }
        setUser(u)
        await u.drugProfiles.calcDrugInteractions()
        setStatus(Status.standard)
    })
  }, [])
  if (status === Status.loading) {
    return <div className='w3-center'><Spinner/><div>Berechne Wechselwirkungen ...</div></div>
  }
  const interactions = user.drugProfiles.getDrugInteractions()
  if (props.teaserOnly) {
    if (interactions) {
      return <Teaser data={interactions} />
    } else {
      return null
    }
  }
  if (interactions === null) {
    return <div>
      <div className='warn-box'>Kein Profil ausgewählt</div>
    </div>
  }
  return <div className='drugInteractionPage'>
    {
      interactions.length === 0 &&
      <div className='neutral-box'>Keine Wechselwirkungen bekannt</div>
    }
    <Teaser data={interactions} teaserTarget={props.teaserTarget}/>
    {
      interactions.map((di, index) => <Interaction
        key={`${index}`}
        data={di}
        user={user}
      />)
    }
  </div>
}

function Teaser(props: {
  data: DrugInteractionDTO[],
  teaserTarget?: string
}) {
  const data = props.data
  if (data.length === 0) { return null }
  let grades = data.map(i => parseInt(i.relevanz)) || [0]
  const worst = `${grades.sort().reverse()[0]}`
  return <>
    <div
      className={`${GetInteractionClass(worst)} text-box text-box-main`}
    >
      <p>
        {
          props.teaserTarget !== undefined &&
          <NavLink
            to={props.teaserTarget || ''}
          >
            {GetInteractionName(worst)}
            
          </NavLink>
        }
        {
          !props.teaserTarget &&
          GetInteractionName(worst)
        }
      </p>
    </div>
  </>
}



function GetInteractionName(relevanz: string): ReactElement {
  switch (relevanz) {
    case '30':
    case '40':
      return <>Für die von Ihnen eingegebenen Arzneimittel werden in unserer Datenbank leichte bis schwere Wechselwirkungen angezeigt.<NavLink className='w3-button w3-yellow notOnDrugInteraction' to='/user/medikamentencheck'>Details anzeigen</NavLink></>
    case '50':
    case '60':
      return <><b>Es wurden Wechselwirkungen gefunden.</b> Sprechen Sie bitte mit Ihrem Arzt oder Apotheker, um zu klären, ob diese für Sie von Bedeutung sind. Beide können Sie auch informieren, ob es Möglichkeiten gibt, das Risiko einer Wechselwirkung zu senken.<NavLink className='w3-button w3-red notOnDrugInteraction' to='/user/medikamentencheck'>Details anzeigen</NavLink></>
  }
  return <>Für die von Ihnen eingegebenen Arzneimittel werden in unserer Datenbank keine Wechselwirkungen angezeigt.</>
}

function GetInteractionClass(relevanz: string): string {
  switch (relevanz) {
    case '30':
    case '40':
      return 'moderate w3-border w3-padding w3-margin w3-border-yellow w3-round-large'
    case '50':
    case '60':
      return 'extreme w3-border w3-padding w3-margin w3-border-red w3-round-large border-width-2'
  }
  return ''
}

function Interaction(props: {
  data: DrugInteractionDTO,
  user: UserDTO,
}) {
  const data = props.data
  const left = props.user.drugProfiles.drugInfo(data.pznLeft)
  const right = props.user.drugProfiles.drugInfo(data.pznRight)
  return <div className={`w3-card w3-margin w3-padding h-list-item risk-${GetInteractionClass(data.relevanz)}`}>
    <p>
      <b>{FormatApoText(right?.langname)}</b> beeinflusst <b>{FormatApoText(left?.langname)}</b>
    </p>
    {
      data.text.map((t, index) => <p
        key={`${data.pznLeft}-${data.pznRight}-${index}`}
        dangerouslySetInnerHTML={{
          __html: FormatApoText(t).replace('~dlinkes AM~d', left?.langname || '').replace('~drechtes AM~d', right?.langname || '')
        }}
      >
      </p>)
    }
  </div>
}
