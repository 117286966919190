import MainMenu from "./MainMenu";
import { useEffect, useState } from "react";
import userService from "../../services/UserService";
import UserDTO from "../../dto/UserDTO";
import BHamburger from "../Buttons/BHamburger";
import { Edit, Plus, Trash } from "react-feather";
import Input from "../Input/Input";
import { DrugProfileDTO } from "../../dto/DrugProfileDTO";
import loginService from "../../services/LoginService";

enum Status {
  loading,
  standard,
  add,
  editName
}

export default function DrugProfiles() {
  const [status, setStatus] = useState(Status.loading)
  const [user, setUser] = useState(new UserDTO())
  const [rerender, setRerender] = useState(0)
  const [newName, setNewName] = useState('')
  useEffect(() => {
    userService.get().then((u) => {
      if (!u) { return }
        setUser(u)
        setStatus(Status.standard)
    })
  }, [])
  const headline = 'Profile verwalten'
  return <>
    <div className='h-header '>
      <div><BHamburger /></div>
      <div className='mobile-headheadline'>{headline}</div>
      <div></div>
    </div>
    <div className='page-headline'>
      <h2>Profil</h2>
      <button
        className='w3-button w3-red'
        onClick={async () => {
          if (window.confirm('Willst Du Dein Profil wirklich unwiederbringlich löschen?')) {
            await user.selfDelete()
            window.location.href = '/'
          }
        }}
      >Mein Profil unwiederbringlich löschen</button>
    </div>
    <div className='page-headline'>
      <h2>{headline}</h2>
    </div>
    <div className='h-main-content'>
      {
        status !== Status.loading &&
        <>
          {
            user.drugProfiles.profiles.map((p, index) => {
              return <Profile
                  key={`drug-profile-${p.id}-${index}`}
                  p={p}
                  onDelete={async () => {
                    if (window.confirm('Profil wirklich löschen?')) {
                      await user.drugProfiles.deleteProfile(p.id)
                      setRerender(rerender + 1)
                    }
                  }}
                  onRename={async (newName) => {
                    await p.rename(newName)
                  }}
                  onClick={async () => {
                    await user.setCurrentProfile(p.id)
                    window.location.href = '/user/medikamente'
                  }}
                />
            })
          }
          {
            status !== Status.add &&
            <button
              className="button-primary w3-margin"
              onClick={() => {
                setStatus(Status.add)
              }}
            ><Plus /></button>
          }
        </>
      }
      {
        status === Status.add &&
        <div className="w3-container">
          <div className='text-box-main'>Neues Profil anlegen</div>
          <Input
            autofocus={true}
            placeholder="Name eingeben"
            cb={async (name: string) => {
              console.log('NAME', name)
              setNewName(name)
            }}
            onEnter={async (name: string) => {
              await user.drugProfiles.createProfile(name)
              setStatus(Status.standard)
              setNewName('')
            }}
            className="w3-margin-bottom"
          />
          <button
            onClick={async () => {
              await user.drugProfiles.createProfile(newName)
              setStatus(Status.standard)
            }}
            className='button-primary'
          >Profil Anlegen</button>
        </div>
      }
    </div>
    <MainMenu />
  </>
}


function Profile(props: {
  p: DrugProfileDTO,
  onDelete: () => void,
  onClick: () => void,
  onRename: (newName: string) => void,
}) {
  const p = props.p
  const [status, setStatus] = useState(Status.loading)
  const [name, setName] = useState(p.name)
  return <div
    
    className={`w3-card w3-margin h-list-item h-drugCard`}
  >
    <div
      className='drugChild drugChildMainElement pointer'
      onClick={() => {
        if (status !== Status.editName) {
          props.onClick()
        }
      }}
    >
      {
        status !== Status.editName ?
        <div>
          {name}
        </div> :
        <div className={`h-flex w3-margin-right`}>
          <input
            className={`h-flexGrow`}
            value={name}
            onChange={(event) => {
              setName(event.target.value)
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                setStatus(Status.standard)
                props.onRename(name)
              }
            }}
          />
          <button
            className={`button-primary`}
            onClick={() => {
              setStatus(Status.standard)
              props.onRename(name)
            }}
          >Speichern</button>
          <button
            className={`button-secondary`}
            onClick={() => {
              setStatus(Status.standard)
            }}
          >Abbrechen</button>
        </div>
      }
    </div>
    <div className='drugChild drugTools'>
      <button
        className='w3-button'
        onClick={async () => {
          if (status === Status.editName) {
            setStatus(Status.standard)
          } else {
            setStatus(Status.editName)
          }
          
        }}
      ><Edit /></button>
      <button
        className='w3-button'
        onClick={props.onDelete}
      ><Trash /></button>
    </div>
  </div>
}