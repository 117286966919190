// import React, { useEffect, useState } from 'react'
import { Routes, Route } from 'react-router-dom'
import Layout from './components/Page/Layout'
// import LoggedOut from './components/Page/LoggedOut'
// import { LoginGuard } from './services/LoginService'
import Landing from './components/Page/Landing'
import AdminLayout from './components/Admin/AdminLayout'
import Root from './components/Page/Root'
import AdminLanding from './components/Admin/AdminLanding'
import UserPreferences from './components/User/Preferences'
import UserLayout from './components/User/UserLayout'
import UserList from './components/Admin/UserList'
import GroupList from './components/Admin/GroupList'
import SetPasswordByToken from './components/User/SetPasswordByToken'
import Preferences from './components/Preference/Preferences'
import Static from './components/Page/Static'
import MyDrugList from './components/Drugs/MyDrugList'
import Home from './components/User/Home'
import StaticUser from './components/User/Static'
import Drugs from './components/User/Drugs'
import Chat from './components/User/Chat'
import DrugCheck from './components/User/DrugCheck'
import DrugPlan from './components/User/DrugPlan'
import DrugSearch from './components/User/DrugSearch'
import Scanner from './components/Input/Scanner'
import ScanScuccess from './components/Input/ScanSuccess'
import DrugConfigWizard from './components/Drugs/DrugConfigWizard'
import HamburgerMenu from './components/Menu/HamburgerMenu'
import DrugProfiles from './components/User/DrugProfiles'
import Register from './components/Register/Register'
import Drawer from './components/Drawer/Drawer'
import FirebaseToast from './components/FirebaseToast'
// import broadcastService from './services/BroadcastService'



function App() {
  // const [loginStatus, setLoginStatus] = useState('')
  /*
  useEffect(() => {
    broadcastService.subscribe('appComponent', (key: string, r) => {
      if (key.search(/login|logout/) > -1) {
        setLoginStatus(r)
      }
    })
  })
  /*
  if (LoginGuard()) {
    return <LoggedOut></LoggedOut>
  }
  */
  // myFirebase.requestPermission()
  return (
    <>
      <FirebaseToast />
      <Routes>
        <Route path="/" element={<Root />}>
          <Route path="user" element={<UserLayout />}>
            <Route path="home" element={<Home></Home>} />
            <Route path="medikamente" element={<Drugs />} />
            <Route path="medikamentensuche" element={<DrugSearch />} />
            <Route path="scanner/ergebnis/*" element={<ScanScuccess />} />
            <Route path="medikament/einstellungen/*" element={<DrugConfigWizard />} />
            <Route path="medikamentenplan" element={<DrugPlan />} />
            <Route path="medikamentencheck" element={<DrugCheck />} />
            <Route path="chat" element={<Chat />} />
            <Route path="scanner" element={<Scanner />} />
            <Route path="drugs" element={<MyDrugList />} />
            <Route path="einstellungen" element={<UserPreferences />} />
            <Route path="profile" element={<DrugProfiles />} />
            <Route path="impressum" element={<StaticUser />} />
            <Route path="aboutus" element={<StaticUser />} />
            <Route path="agbs" element={<StaticUser />} />
            <Route path="support" element={<StaticUser />} />
            <Route path="datenschutz" element={<StaticUser />} />
            <Route path="contact" element={<StaticUser />} />
          </Route>
          <Route path="admin" element={<AdminLayout />}>
            <Route index element={<AdminLanding />} />
            <Route path="groupList" element={<GroupList />} />
            <Route path="preferences" element={<Preferences />} />
            <Route path="userList" element={<UserList />} />
          </Route>
          <Route path="page/:name" element={<div>No Content</div>} />
          <Route path="*" element={<Layout />}>
            <Route path="impressum" element={<Static />} />
            <Route path="aboutus" element={<Static />} />
            <Route path="agbs" element={<Static />} />
            <Route path="support" element={<Static />} />
            <Route path="datenschutz" element={<Static />} />
            <Route path="contact" element={<Static />} />
            <Route path="register" element={<Register /> }></Route>
            <Route path="setPassword/:hash" element={<SetPasswordByToken />} />
            <Route path="login" element={<Landing />} />
            <Route path="logout" element={<Landing />} />
            <Route index element={<Landing />}/>
          </Route>
        </Route>
      </Routes>
      <Drawer />
      <HamburgerMenu />
    </> 
  )
}

export default App;
