
import MainMenu from "./MainMenu";
import BHamburger from "../Buttons/BHamburger";
import StaticPage from "../Page/Static";

export default function Static() {
  return <>
    <div className='h-header '>
      <div><BHamburger /></div>
      <div>Home</div>
      <div></div>
    </div>
    <div className='h-main-content'>
      <StaticPage />
    </div>
    <MainMenu />
  </>
}
