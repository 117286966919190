import DrugDTO from '../../dto/DrugDTO'
import GenericDrugIcon from '../../static/icons/map-pharmacy.svg'
import DrugIcons from '../../config/DrugIcons.json'

export default function DrugIcon(props: {
  drug: DrugDTO
}) {
  const icon = DrugIcons.find(dI => dI.key === props.drug.dosageForm)
  if (icon) {
    return <img src={`/medIcons/${icon.icon}.png`} alt='drugIcon' className='drugIcon w3-padding' />
  }
  return <img src={GenericDrugIcon} alt='drugIcon' className='drugIcon w3-padding' />
}
