import { useState } from "react"
import Input from "../Input/Input"
import i18n from "../../services/i18n"
import Spinner from "../Spinner/Spinner"
import Com from "../../services/ComService"


enum Status {
  init,
  loading,
  done,
  err,
}


export default function Register (props: {}) {
  const [username, setUsername] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [state, setStatus] = useState(Status.init)
  const startRgister = async () => {
    if (username === '' || username.search(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) === -1) {
      setErrMsg('Bitte eine gültige E-Mail Adresse eingeben!')
      return
    }
    setErrMsg('')
    setStatus(Status.loading)
    const step1 = await Com('POST:register', {
      email: username
    }) as {hash: string}
    const step2 = await Com('PUT:register', {
      hash: step1.hash,
      email: username
    }, 1) as {status: string}
    console.log(step2)
    if (step2.status !== 'okay') {
      setStatus(Status.err)
      return
    }
    setStatus(Status.done)
  }
  if (state === Status.loading) {
    return <div>
      <div className='w3-container'>
        <h1>Registrieren</h1>
        <Spinner />
      </div>
    </div>
  }
  if (state === Status.done) {
    return <div>
      <div className='w3-container'>
        <h1>Registrieren</h1>
        <div className='info-box'>
          Überprüfen Sie Ihr E-Mail Konto, klicken Sie auf den Link in der Mail und vergeben Ihr neues Passwort um den Prozess abzuschließen!
        </div>
      </div>
    </div>
  }
  if (state === Status.err) {
    return <div>
      <div className='w3-container'>
        <h1>Registrieren</h1>
        <div className='alert-box'>
          Die Registrierung hat nicht funktionieren. Bitte später noch einmal versuchen!
        </div>
      </div>
    </div>
  }
  return <div>
    <div className='w3-container'>
      <h1>Registrieren</h1>
      <p>Zum registrieren die E-Mail-Adresse eintragen. Wir schicken eine Bestätigungsmail mit einem Link zum erstellen eines Passwortes.</p>
    </div>
    <div className="login-box">
    <div className='w3-container'>
      <label>E-Mail</label>
      <Input
        className='w3-input'
        value={username}
        autofocus={true}
        placeholder={i18n.get('username')}
        cb={(username) => setUsername(username)}
      ></Input>
    </div>
    {
      errMsg &&
      <div className='w3-container'>
        <div className='warn-box'>
          {errMsg}
        </div>
      </div>
    }
    <div className='w3-margin'>
      <button
        className='button-primary'
        onClick={() => {
          startRgister()
        }}
      >{i18n.get('registerbuttontext')}</button>
    </div>
    
    <footer className='w3-container'></footer>
  </div>
    </div>
}