import MainMenu from "./MainMenu";
import { useEffect, useState } from "react";
import userService from "../../services/UserService";
import UserDTO from "../../dto/UserDTO";
import DrugSearch from "../Drugs/DrugSearch";
import BHamburger from "../Buttons/BHamburger";
import { PreferencesDTO } from "../../dto/PreferenceDTO";
import { Bell, User } from "react-feather";
import Avatar from '../../static/icons/open-person.svg'
import './Profile.scss';
import { useNavigate } from "react-router-dom";
import { myFirebase } from "../../services/firebase";


enum Status {
  loading,
  standard,
}

export default function Home() {
  const headline = 'Home'
  const [status, setStatus] = useState(Status.loading)
  const [user, setUser] = useState(new UserDTO())
  const [prefs] = useState(new PreferencesDTO())
  const navigate = useNavigate()
  useEffect(() => {
    userService.get().then((u) => {
      if (!u) { return }
        setUser(u)
        prefs.init().then(() => {
          console.log('prefs loaded')
          setStatus(Status.standard)
        })
    })
  }, [])
  return <>
    <div className='h-header '>
      <div><BHamburger /></div>
      <div className='mobile-headheadline'>{headline}</div>
      <div></div>
    </div>
    <div className='h-main-content'>

      <div className='h-part'>
        <h3 className='w3-container'>Aktuelles</h3>
        <div className='w3-card w3-round w3-margin w3-padding w3-white'>
          <div dangerouslySetInnerHTML={{__html: prefs.getPrefV1('content', 'news1')}}></div>
        </div>
      </div>

      <div className='h-part'>
        <DrugSearch
          set={async (pzn: string) => {
            // Set means save to user and then proceed to detail page
            const result = await user.drugProfiles.addPzn(pzn) as {id: number}
            window.location.href = '/user/medikament/einstellungen/' + result.id
          }}
        />
      </div>

      <div className='h-part'>
        <h3 className="w3-container">Profile</h3>
        <div className="w3-row w3-left-align">
          {
            user && user.drugProfiles && user.drugProfiles.profiles &&
            user.drugProfiles.profiles.map((dp, index) => {
              const intakes = dp.getTodayIntakes()
              const upcoming = intakes.upcoming
              const todayCount = intakes.upcoming.length + intakes.past.length
              return <div
                key={`dp-carussel-${dp.id}`}
                className='w3-col s12 m12 l6 home-profile-item '
              >
                <div className='w3-card w3-margin profile-box'>
                  <div
                    className='w3-container w3-center profile-avatar-box pointer'
                    onClick={async () => {
                      await user.setCurrentProfile(dp.id as number)
                      navigate('/user/medikamente')
                    }}
                  >
                    <img src={Avatar} alt='Avatar' className='avatar w3-padding' />
                  </div>
                  <div className='w3-center'>
                    <div className='profile-name-box'>{dp.name}</div>
                    <div>Einnahmen heute: {todayCount}</div>
                    <div><b>Nächste Einnahmen:</b></div>
                  </div>
                  {
                    upcoming.map((u, i) => {
                      const drug = user.drugProfiles.getUserdrugById(u.drugId)
                      return <div
                        className='w3-border-top w3-row'
                        key={`${index}-${i}`}>
                        <b className="w3-col s12 w3-center">{u.key2} Uhr</b>
                        <div className="w3-col s2 m3 w3-center">
                          {u.value1} {drug?.props.getPropValue1('drugInfo', 'measureUnit')}
                        </div>
                        <div className='w3-col s10 m6'>
                          {drug?.langname}
                        </div>
                      </div>
                    })
                  }
                  {
                    upcoming.length === 0 &&
                    <div className='neutral-box w3-center'>
                      Heute keine Einnahmen mehr geplant
                    </div>
                  }
                </div>
              </div>
            })
          }
          {
            user && (!user.drugProfiles || !user.drugProfiles.profiles || user.drugProfiles.profiles.length === 0) &&
            <div className="warn-box">Noch keine Angaben gemacht. Tragen Sie Medikamente ein. Das geht in der <a href="/user/medikamentensuche">Medikamentensuche</a></div>
          }
        </div>
      </div>
    </div>

    <MainMenu />
  </>
}