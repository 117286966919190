import { NavLink, Outlet } from "react-router-dom"
import I18n from "../I18n"
import footerContent from '../../config/publicFooter.json'
const Layout = () => {
  return <div className='UserLayoutHolder'>
    <Outlet></Outlet>
    <div className='publicFooter'>
      {
        footerContent.map(c => {
          return <NavLink
            key={`publicFooter-${c.key}`}
            to={c.target}
            className={`w3-padding`}
          >
            <I18n value={c.key} />
          </NavLink>
        })
      }
    </div>
  </div>
}
export default Layout
