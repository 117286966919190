import { useEffect, useState } from "react"
import { FilesDTO } from "../../dto/FileDTO"
import broadcastService from "../../services/BroadcastService"
import i18n from "../../services/i18n"
import Spinner from "../Spinner/Spinner"
import './PictureList.scss'

enum Status {
  loading,
  done
}
export default function PictureList(props: {
  kind: string,
  selectFile?: (pictureId: number) => void
}) {
  const [Files] = useState(new FilesDTO({
    kind: props.kind
  }))
  const [status, setStatus] = useState(Status.loading)
  const [Rerender, setRerender] = useState(0)
  useEffect(() => {
    Files.getFromServer().then(() => {
      setStatus(Status.done)
    })
    broadcastService.subscribe('PictureList', (key, body) => {
      if (key === 'fileUploadDone') {
        Files.getFromServer().then(() => {
          setRerender(Rerender + 1)
        })
      }
    })
  }, [])
  const RenderFiles = () => {
    if (Files.files.length === 0) {
      return <div>
        Keine Dateien vorhanden
      </div>
    }
    return <div className={`iteration-${Rerender}`}>
      {
        Files.files.map((f, i) => <div
          key={`file-list-item-${f.id}-${i}`}
          className='PLItem'
        >
          <div
            onClick={() => {
              if (props.selectFile) {
                props.selectFile(f.id)
              }
            }}
            className='thumb'
            style={{
              backgroundImage: f.getCSSString()
            }}
          ></div>
          <span
            className='PLName'
            onClick={() => {
              if (props.selectFile) {
                props.selectFile(f.id)
              }
            }}
          >
            {f.name}
          </span>
          <button
            className='w3-button w3-ripple w3-red'
            onClick={async () => {
              console.log('Delete this file')
              if(window.confirm(i18n.get('deleteFileOk'))) {
                await Files.deleteFile(f.id)
                setRerender(Rerender + 1)
              }
            }}
          >
            &times;
          </button>
        </div>)
      }
    </div>
  }
  const Content = status === Status.loading ? <Spinner /> : RenderFiles()
  return <div className='w3-container PictureList'>
    {Content}
  </div>
}