import { useEffect, useState } from "react";
import { Edit, Trash } from "react-feather";
import { NavLink } from "react-router-dom";
import DrugDTO from "../../dto/DrugDTO";
import UserDTO from "../../dto/UserDTO";
import userService from "../../services/UserService";
import BPlus from "../Buttons/BPlus";
import './MyDrugs.scss'
import { DosageDisplayName } from "../../dto/DosageForm";
import DrugInteraction from "./DrugInteraction";
import FormatApoText from "../../services/FormatApoText";
import broadcastService from "../../services/BroadcastService";
import DrugIcon from "../Icons/DrugIcon";

enum Status {
  loading,
  standard,
  search,
  interactions,
}

export default function MyDrugs () {
  const [status, setStatus] = useState(Status.loading)
  const [user, setUser] = useState(new UserDTO())
  useEffect(() => {
    userService.get().then((u) => {
      if (!u) { return }
        setUser(u)
        setStatus(Status.standard)
    })
  }, [])
  // const user = userService.get()
  if (status === Status.loading) {
    return <div>Lade ...</div>
  }
  async function deletePzn(pzn: string) {
    setStatus(Status.loading)
    await user.drugProfiles.removePzn(pzn)
    setStatus(Status.standard)
  }
  const list = user.drugProfiles.getDrugs()
  return <>
    <DrugInteraction teaserOnly={true} teaserTarget="/user/medikamentencheck"/>
    {
      list.map((d, index) => <Drug
          key={`drug-list-pzn-${index}`}
          Drug={d}
          delete={deletePzn}
        />)
    }
    {
      list.length === 0 &&
      <div className='info-box w3-margin'>Noch keine Medikamente eingetragen. <br />Klicke auf<br /> <BPlus target='/user/medikamentensuche' /><br /> um Medikamente hinzuzufügen.</div>
    }
  </>
}

function Drug (props: {
  Drug: DrugDTO,
  delete: (pzn: string) => void
}) {
  const drug = props.Drug
  return <div
    className='w3-card w3-margin h-list-item h-drugCard'
  >
    <div className='drugChild drugIcon'><DrugIcon drug={drug} /></div>
    <div
      className='drugChild drugChildMainElement pointer'
      title={drug.pzn}
      onClick={() => {
        broadcastService.broadcast('drawer', {
          drugId: drug.id,
          pzn: drug.pzn,
          kind: 'druginfo',
          name: drug.getName(),
        })
      }}
    >
      <div>
        {
          FormatApoText(drug.langname)
        }
      </div>
    </div>
    <div className='doNotDisplay drugChild drugTools'>
      <button
        className={`button-tool`}
      >
        <NavLink
            to={`/user/medikament/einstellungen/${drug.id}`}
        >
            <Edit />
        </NavLink>
      </button>
    </div>
    <div className='drugChild drugTools'>
      <button
        className='button-tool'
        onClick={() => {
          if (!window.confirm(`${drug.langname} wirklich entfernen?`)) { return }
          props.delete(drug.pzn)
        }}
      >
        <Trash />
      </button>
    </div>
  </div>
}
