import { useEffect, useState } from "react"
import { AlignLeft, Calendar, CheckSquare, Edit, Plus, Star, Trash, UserPlus, Users, X } from "react-feather"
import GroupDTO from "../../dto/GroupDTO"
import GroupsDTO from "../../dto/GroupsDTO"
import i18n from "../../services/i18n"
import loginService from "../../services/LoginService"
import userService from "../../services/UserService"
import Input from "../Input/Input"
import Spinner from "../Spinner/Spinner"
import FieldsEditor from "./FieldsEditor"
import GContentEditor from './GContentEditor'
import { SelectUserList } from "./UserList"

enum Status {
  loading,
  done,
  new,
  selectUser,
  selectAdminUser,
  editFields,
  addUser,
  editRules,
  editName,
  editUrl,
  showBookings,
  editContent,
}

const RenderGroup = (props: {
  group: GroupDTO,
  cb: () => void,
  deleteGroupNotice: (id: number) => void,
}) => {
  const g = props.group
  const [status, setStatus] = useState(Status.done)
  const [Rerender, setRerender] = useState(0)
  const [newMail, setNewMail] = useState('')
  const [name, setName] = useState(g.name)
  const [url, setUrl] = useState(g.getUrl())
  
  useEffect(() => {
    userService.get().then(u => setRerender(Rerender + 1))
  }, [])
  const actionButtons = [
    {
      className: 'w3-button w3-green',
      onClick: () => {
        setStatus(Status.selectAdminUser)
      },
      content: <Star />,
      hideAtState: [Status.selectAdminUser],
      onlyGroupAdmin: true
    },
    {
      className: 'w3-button w3-yellow',
      onClick: () => {
        setStatus(Status.selectUser)
      },
      content: <Users />,
      hideAtState: [Status.selectUser],
      onlyGroupAdmin: false
    },
    {
      className: 'w3-button w3-green',
      onClick: () => {
        setStatus(Status.addUser)
      },
      content: <UserPlus />,
      hideAtState: [Status.addUser],
      onlyGroupAdmin: true
    },
    {
      className: 'w3-button w3-red',
      onClick: async () => {
        if (!window.confirm(`Wirklich Gruppe ${g.name} mit id ${g.id} löschen?`)) { return }
        const id = g.id + 0
        await groups.deleteGroup({id: id})
        props.deleteGroupNotice(id)
        // props.cb()
      },
      content: <Trash />,
      hideAtState: [],
      onlyGroupAdmin: true
    }
  ]
  const displayUrl = g.getUrl() ? window.location.protocol + '//' + window.location.host + '/page/' + g.getUrl() : ''
  return <div
    key={g.id}
    className='w3-container w3-card w3-padding w3-margin'
  >
    {
      g.isAdmin() &&
      <div><Star /></div>
    }
    
    <div className='w3-right'>
      {
        actionButtons.map((a, index) => <button
          key={`${g.id}-action-button-${index}`}
          className={a.className + (
              a.hideAtState.some(s => s === status) || a.onlyGroupAdmin && (!g.isAdmin() && !loginService.IsAdmin()) ? ' w3-hide' : ''
            )}
          onClick={() => {
            a.onClick()
          }}
        >
          {a.content}
        </button>)
      }
    </div>
    {
      status !== Status.editName &&
      <h2>{g.name} <button
        className='w3-btn'
        onClick={() => setStatus(Status.editName)}
      ><Edit /></button></h2>
    }
    {
      status === Status.editName &&
      <>
        <Input
          cb={(newName) => {
            setName(newName)
          }}
          value={name}
          onEnter={async () => {
            await g.rename(name)
            setStatus(Status.done)
          }}
        />
        <button
          className='w3-button'
          onClick={async () => {
            await g.rename(name)
            setStatus(Status.done)
          }}
        >Speichern</button>
      </>
    }
    {
      status === Status.selectUser &&
      <div className='w3-row'>
        <button
          className='w3-button w3-yellow w3-right'
          onClick={() => {
            setStatus(Status.done)
          }}
        >
          <X />
        </button>
        <h3>{i18n.get('CurrentUsers')}</h3>
        <SelectUserList
          className='left w3-col s6 w3-border'
          childClassName='deleteBox'
          users={g.users}
          cb={async (id: number) => {
            await g.removeUser(id)
            setRerender(Rerender + 1)
          }}
        />
        <h3>{i18n.get('AddUsers')}</h3>
        <SelectUserList
          className='right w3-col s6 w3-border'
          childClassName='checkBox'
          exclude={g.users.map(u => u.id)}
          cb={async (id: number) => {
            await g.addUser(id)
            setRerender(Rerender + 1)
          }}
        />
      </div>
    }
    {
      status === Status.selectAdminUser &&
      <div className='w3-row'>
        <button
          className='w3-button w3-yellow w3-right'
          onClick={() => {
            setStatus(Status.done)
          }}
        >
          <X />
        </button>
        <h3>{i18n.get('AdminUsers')}</h3>
        <SelectUserList
          className='left w3-col s6 w3-border'
          childClassName='deleteBox'
          users={g.getAdmins()}
          cb={async (id: number) => {
            await g.removeAdmin(id)
            setRerender(Rerender + 1)
          }}
        />
        <h3>{i18n.get('CurrentUsers')}</h3>
        <SelectUserList
          className='right w3-col s6 w3-border'
          childClassName='checkBox'
          users={g.getStandardUsers()}
          cb={async (id: number) => {
            await g.addAdmin(id)
            setRerender(Rerender + 1)
          }}
        />
      </div>
    }
    {
      status === Status.addUser &&
      <div className='w3-container'>
        <Input
          value={newMail}
          cb={(v) => setNewMail(v)}
          placeholder={i18n.get('inviteEmailTwoGroup')}
        />
        <button
          className='w3-button w3-ripple w3-green'
          onClick={async () => {
            await g.addUserByMail(newMail)
            setNewMail('')
            setStatus(Status.selectUser)
          }}
        >Ok</button>
      </div>
    }
    {
      status === Status.editRules &&
      <div>NO CONTENT</div>
    }
    {
      status === Status.editFields &&
      <FieldsEditor ugroup={g} />
    }
    {
      status === Status.editContent &&
      <GContentEditor ugroup={g} />
    }
    {
      status === Status.showBookings &&
      <div>NO CONTENT</div>
    }
  </div>
}

const groups = new GroupsDTO()

const GroupAdd = (props: {
  cb: () => void
}) => {
  const [status, setStatus] = useState(Status.done)
  const [Name, setName] = useState('')
  const send = async () => {
    await groups.newGroup({
      name: Name
    })
    setName('')
    setStatus(Status.done)
    props.cb()
  }
  if (!loginService.IsAdmin()) {
    return null
  }
  if (status === Status.done) {
    return <button
        className='w3-button w3-green w3-ripple'
        onClick={() => {
          setStatus(Status.new)
        }}
      >+</button>
  }
  return <div>
    <Input
      value={Name}
      placeholder='name'
      cb={(name) => setName(name)}
      onEnter={send}
    ></Input>
    <button
      className='w3-button w3-green'
      onClick={send}
    >Los</button>
  </div>
}

const GroupList = (props: {}) => {
  const [status, setStatus] = useState(Status.loading)
  const [Render, setRender] = useState(0)
  const [deletedGId, setDeletedGId] = useState(-1)
  useEffect(() => {
    groups.getFromServer().then(() => {
      setStatus(Status.done)
    })
  }, [])
  if (status === Status.loading) {
    return <Spinner />
  }
  const list = groups.getList()
  return <div className='h-main-content'>
    {
      deletedGId > -1 &&
      <div>
        <button
          className='w3-button w3-yellow w3-margin'
          onClick={async () => {
            const group = groups.getById(deletedGId)
            if (!group) { return }
            await group.deletePropByKey1Key2('groupconf', 'deleted')
            groups.getFromServer().then(() => {
              setDeletedGId(-1)
              setStatus(Status.done)
            })
          }}
        >Gruppe löschen rückgängig machen</button>
      </div>
    }
    <GroupAdd
      cb={() => { setRender(Render + 1) }}
    ></GroupAdd>
    {list.map(u => <RenderGroup
      key={`RenderGroup-${u.id}`}
      group={u}
      cb={() => { setRender(Render + 1) }}
      deleteGroupNotice={(id) => {
        setDeletedGId(id)
      }}
    />)}
    {
      list.length === 0 &&
      <div
        className='w3-center w3-margin w3-padding'
      >{i18n.get('noGroupsAvailable')}</div>
    }
  </div>
}

export default GroupList
