import { useState } from 'react'
import './Multibutton.scss'

export default function MultiButton(props: {
  value: number | string,
  cb: (newVal: number | string) => void,
  values: number[] | string[],
  other?: string | boolean,
}) {
  const [currentValue, setCurrentValue] = useState(props.value + '')
  const [otherSelected, setOtherSelected] = useState(false)
  let foundValue = false
  return <div className={`multibutton`}>
    {
      props.values.map((v, i) => {
        const thisIsTheValue = (v === currentValue)
        const activeClass = thisIsTheValue ? 'multibutton-item-active' : 'multibutton-item-inactive'
        const className = `multibutton-item w3-padding ${activeClass}`
        if (thisIsTheValue) {
          foundValue = true
        }
        return <div
          className={className}
          key={`${v}-${i}`}
          onClick={() => {
            setCurrentValue(v + '')
            setOtherSelected(false)
            props.cb(v)
          }}
        >{v}</div>
      })
    }
    {
      props.other &&
      <>
        <div
          className={`multibutton-item w3-padding ${!foundValue ? 'multibutton-item-active' : 'multibutton-item-inactive'}`}
          onClick={() => {
            setCurrentValue('')
            setOtherSelected(true)
          }}
        >andere</div>
        {
          (!foundValue || otherSelected) &&
          <>
            <input
              value={currentValue}
              onChange={(a) => {
                setCurrentValue(a.target.value)
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  props.cb(currentValue)
                }
              }}
              type={props.other === 'number' ? 'number' : undefined}
            />
            {
              currentValue !== props.value &&
              <button
                className='button-primary'
                onClick={() => {
                  props.cb(currentValue)
                }}
              >Speichern</button>
            }
          </>
        }
      </>
    }
  </div>
}
