import i18n from "../../services/i18n"
import './DaySelector.scss'

const days = [
  {
    short: 'mo',
    key: 'monday',
  },
  {
    short: 'tu',
    key: 'tuesday',
  },
  {
    short: 'we',
    key: 'wednesday',
  },
  {
    short: 'th',
    key: 'thursday',
  },
  {
    short: 'fr',
    key: 'friday',
  },
  {
    short: 'sa',
    key: 'saturday',
  },
  {
    short: 'su',
    key: 'sunday',
  },
]

export default function DaySelector(props: {
  days: string,
  className?: string
  newDays?: (newDays: string) => void,
}) {
  return <div className={(props.className || '') + ' dayselector '}>
    {
      days.map(d => <Day
        key={`${d.short}`}
        value={d.short}
        active={props.days.search(d.short) > -1}
        activeClass={(props.newDays ? 'button-primary' : 'label-primary')}
        passiveClass={(props.newDays ? 'button-secondary' : 'label-secondary')}
        modifyDay={(day: string, newValue: boolean) => {
          if (props.newDays) {
            props.newDays(addRemoveDay(props.days, day, newValue))
          }
        }}
      />)
    }
  </div>
}

export function currentShortDay(): string {
  const now = new Date()
  let n = now.getDay() - 1
  if (n === -1) {
    n = 6
  }
  return days[n].short
}

function Day (props: {
  value: string,
  active: boolean,
  modifyDay: (day: string, newValue: boolean) => void,
  activeClass: string,
  passiveClass: string,
}) {
  const day = short2Day(props.value)
  if (!day) { return null }
  return <button
    onClick={() => {
      props.modifyDay(props.value, !props.active)
    }}
    className={`${props.active ? props.activeClass : props.passiveClass}`}
  >
    {day.displayShort}
  </button>
}

function short2Day (short: string) {
  const day = days.find(d => d.short === short)
  if (!day) { return undefined }
  return {...day, ...{displayName: i18n.get(day.key), displayShort: i18n.get('short-' + day.key)}}
}

function addRemoveDay(currentDays: string, day: string, add: boolean): string {
  return days.filter(d => {
    if (d.short === day && !add) { return false }
    if (d.short === day && add) { return true }
    if (currentDays.search(d.short) > -1) { return true }
    return false
  }).map(d => d.short).join(',')
}