import { useState } from 'react'
import './DropDown.scss'

enum Status {
  normal,
  other,
}
export default function DropDown(props: {
  value: number | string,
  cb: (newVal: number | string) => void,
  options: {displayValue: string, value: number | string}[],
  lastElement?: any,
  other?: string,
}) {
  const value = props.value === undefined ? '' : props.value
  let foundMatch = false
  const [currentValue, setCurrentValue] = useState(value)
  const [status, setStatus] = useState(Status.normal)
  return <div className={`h-drop-down w3-dropdown-hover`}>
    <button className='button-primary'>
      {
        props.options.find(o => o.value === value)?.displayValue || ((props.other &&value) ? value : 'Auswählen')
      }
    </button>
    <div className='w3-dropdown-content w3-bar-block w3-border'>
      {
        props.options.map((o, i) => {
          const active = (o.value === currentValue)
          if (active) {
            foundMatch = true
          }
          return <div
            className={`dropdown-option w3-padding ${active ? 'dropdown-option-active' : 'dropdown-option-inactive'}`}
            key={`${o.value}-${i}`}
            onClick={() => {
              props.cb(o.value)
            }}
          >{o.displayValue}</div>
        })
      }
      {
        props.other &&
        <>
          <div
            className={`dropdown-option w3-padding ${(!foundMatch || status === Status.other) ? 'dropdown-option-active' : 'dropdown-option-inactive'}`}
            onClick={() => {
              if (status !== Status.other) {
                setCurrentValue('')
              }
              setStatus(Status.other)
            }}
          >
            {props.other}
          </div>
          {
            (!foundMatch || status === Status.other) &&
            <>
              <input
                className='w3-padding'
                value={currentValue}
                onChange={(event) => {
                  setCurrentValue(event.target.value)
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    props.cb(currentValue)
                  }
                }}
              />
              {
                currentValue !== value &&
                <button
                  className='button-primary'
                  onClick={() => {
                    props.cb(currentValue)
                  }}
                >Speichern</button>
              }
            </>
          }
        </>
      }
      {
        props.lastElement !== undefined &&
        props.lastElement
      }
    </div>
  </div>
}
