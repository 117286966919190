import { NavLink } from "react-router-dom";
import BHamburger from "../Buttons/BHamburger";
import DrugInteraction from "../Drugs/DrugInteraction";
import MainMenu from "./MainMenu";
import ProfileSelector from "./ProfileSelector";
import { Plus } from "react-feather";
import Legal from "../Drugs/Legal";
import PrefContent from "../Preference/PrefContent";

export default function DrugCheck() {
  const headline = 'Medikamenten Check'
  return <>
    <div className='h-header '>
      <div><BHamburger /></div>
        <div className='mobile-headheadline'>{headline}</div>
        <div></div>
      </div>
      <div className='page-headline'>
        <h2>{headline}</h2>
        <ProfileSelector />
      </div>
      <div className='h-main-content'>
      <DrugInteraction />
      <NavLink
        to={'/user/medikamentensuche'}
        className='h-flex-start h-flex-align-center no-text-decoration w3-margin'
        >
          <span
            className={`button-primary w3-margin-right`}
          >
            <Plus />
          </span>
          <div>
            Weitere Medikamente hinzufügen
          </div>
      </NavLink>
      <Legal />
      <PrefContent className='w3-container' key2='medikamentencheckFooter' />
    </div>
    <MainMenu />
  </>
}