import { Outlet } from "react-router-dom"
import Menu from "../Menu/Menu"
import menuConfig from '../../config/adminMenu.json'

const AdminLayout = () => {
  return <div className='scrollY'>
    <Menu
      config={menuConfig}
    />
    <Outlet></Outlet>
  </div>
}
export default AdminLayout