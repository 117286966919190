import Com from "../services/ComService";
import PropDTO, { IProp } from "./PropDTO";

export default class PropsDTO {
  props: PropDTO[] = []
  actionPath: string = ''
  basicBody: object = {}
  constructor(data?: IProp[], actionPath?: string, basicBody?: object) {
    if (!data) {
      return
    }
    this.props = data.map(d => new PropDTO(d))
    if (actionPath) {
      this.actionPath = actionPath
    }
    if (basicBody) {
      this.basicBody = basicBody
    }
  }

  getPropsByKey1(key1: string) {
    return this.props.filter(p => p.key1 === key1)
  }

  getProps(key1: string, key2?: string) {
    if (key2 !== undefined) {
      return this.props.filter(p => p.key1 === key1 && p.key2 === key2)
    }
    return this.props.filter(p => p.key1 === key1)
  }
  
  getProp(key1: string, key2: string) {
    return this.props.find(p => p.key1 === key1 && p.key2 === key2) || null
  }

  getPropValue1(key1?: string, key2?: string): string | null {
    if (!key1 || !key2) { return null }
    const prop = this.getProp(key1, key2)
    if (prop) { return prop.value1 }
    return null
  }

  getPropValue2(key1?: string, key2?: string): string | null {
    if (!key1 || !key2) { return null }
    const prop = this.getProp(key1, key2)
    if (prop) { return prop.value2 }
    return null
  }

  public async setProp(key1?: string, key2?: string, value1?: string, value2?: string, key2New?: string) {
    if (!key1 || !key2) { return null }
    this.props = this.props.filter(p => !(p.key1 === key1 && p.key2 === key2))
    const result = await Com(`PATCH:${this.actionPath}`, {...this.basicBody, ...{
      key1: key1,
      key2: key2,
      value1: value1,
      value2: value2 || '',
      newKey2: key2New || key2,
    }})
    const newProp = new PropDTO(result as IProp)
    this.props.push(newProp)
    return newProp
  }

  public async deleteProp(key1: string, key2: string) {
    if (!key1 || !key2) { return }
    this.props = this.props.filter(p => !(p.key1 === key1 && p.key2 === key2))
    await Com(`DELETE:${this.actionPath}`, {...this.basicBody, ...{
      key1: key1,
      key2: key2,
    }})
  }

}