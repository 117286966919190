import { useState } from "react"
import { twoDigits } from '../../services/DateService'
import './TimePickerDD.scss'
import { create } from "domain"

enum Status {
  hours,
  minutes,
}
export default function TimePickerDD(props: {
  timeIn: string,
  cb: (timeOut: string) => void,
}) {
  const [hours, setHours] = useState(props.timeIn.split(':')[0])
  const [minutes, setMinutes] = useState(props.timeIn.split(':')[1])
  const [status, setStatus] = useState(Status.hours)
  const hourArray = createArray(0, 24, 1)
  const minuteArray = createArray(0, 59, 15)
  function setTime(option: {
    hour?: string,
    minute?: string,
  }) {
    let useHour = hours || '00'
    let useMinute = minutes || '00'
    if (option.hour !== undefined) {
      useHour = option.hour
    }
    if (option.minute !== undefined) {
      useMinute = option.minute
    }
    props.cb(`${useHour}:${useMinute}`)
  }
  return <div className='timePickerDD'>
    <select
      onChange={(event) => {
        setHours(event.target.value)
        setTime({hour: event.target.value})
      }}
      value={hours}
    >
      {
        hourArray.map((hour) => {
          return <option
            key={`hour-select-${hour}`}
            value={hour}
          >{hour}</option>
        })
      }
    </select>
    <span className='separator'>:</span>
    <select
      onChange={(event) => {
        setMinutes(event.target.value)
        setTime({minute: event.target.value})
      }}
      value={minutes}
    >
      {
        minuteArray.map((minute) => {
          return <option
            key={`minute-select-${minute}`}
            value={minute}
          >{minute}</option>
        })
      }
    </select>
    <span className='separator'>Uhr</span>
  </div>
  
}

function createArray(min: number, max: number, step: number): string[] {
  let out = []
  let current = min + 0
  while (current <= max) {
    out.push(twoDigits(current))
    current += step
  }
  return out
}