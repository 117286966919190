import { useEffect, useState } from "react"
import { PreferencesDTO } from "../../dto/PreferenceDTO"


enum Status {
  init,
  loading,
  done,
  success,
}

export default function Static() {
  const nameRaw = window.location.href.match(/[^/]+$/)
  const isUserPage = window.location.href.search('user') > -1
  const name = (nameRaw) ? nameRaw[0] : ''
  const [prefs] = useState(new PreferencesDTO())
  const [status, setStatus] = useState(Status.loading)
  useEffect(() => {
    prefs.init().then(() => {
      console.log('prefs loaded')
      setStatus(Status.done)
    })
  }, [])
  return <div className='w3-container'>
    {
      !isUserPage &&
      <a href="/">Zurück zur Login Seite</a>
    }
    {
      status === Status.loading &&
      <div>Laden ...</div>
    }
    {
      status === Status.done &&
      <div dangerouslySetInnerHTML={{__html: prefs.getPrefV1('content', name)}}></div>
    }
  </div>
}
