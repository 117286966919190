const protos = [
  ['A25','Ä'],
  ['a25','ä'],
  ['O25','Ö'],
  ['o25','ö'],
  ['U25','Ü'],
  ['u25','ü'],
  ['a63','α'],
  ['b63','ß'],
  ['D63','∆'],
  ['d63','δ'],
  ['e63','ε'],
  ['g63','γ'],
  ['l63','λ'],
  ['m63','μ'],
  ['O42','Ø'],
  ['p63','π'],
  ['s39','ß'],
  ['W63','Ω'],
  ['w63','ω'],
  ['347','°'],
  ['348','‰'],
  ['360','+'],
  ['361','&'],
  ['362','*'],
  ['363','§'],
  ['364','%'],
  ['367','$'],
  ['372','@'],
  ['380','®'],
  ['375','×'],
  ['420','('],
  ['421',')'],
  ['422','['],
  ['423',']'],
  ['424','{'],
  ['425','}'],
  ['426','<'],
  ['427','>'],
  ['323',':'],
  ['324','!'],
]

const Protos = protos.map(p => {
  return {
    scope: new RegExp(`${p[0]}`, 'g'),
    value: p[1],
    rScope: new RegExp(`[${p[1]}]`, 'g'),
    rValue: p[0],
  }
})

export default function FormatApoText(tin?: string) {
  if (tin === undefined) {
    return ''
  }
  let t: string = tin
  Protos.forEach(p => {
    t = t.replace(p.scope, p.value)
  })
  t = t.replace(new RegExp('~n', 'g'), '<br />') // Zeilenumbruch
  t = t.replace(new RegExp('~i', 'g'), '&nbsp;&nbsp;') // Tabulator
  t = t.replace(new RegExp('~g', 'g'), '&bsp;') // geschütztes Leerzeichen
  t = t.replace(new RegExp('~b', 'g'), ' ') // geschütztes Leerzeichen
  t = t.replace(new RegExp('~w', 'g'), '') // Worttrennmöglichkeiten (unterstützen wir nicht)
  t = t.replace(new RegExp('~h(.)', 'g'), '<sup>$1</sup>') // Hochstellen des folgenden Zeichens
  t = t.replace(new RegExp('~t(.)', 'g'), '<sub>$1</sub>') // Hochstellen des folgenden Zeichens
  t = t.replace(new RegExp('~u', 'g'), '') // Unterstreichen (unterstützen wir nicht)
  t = t.replace(new RegExp('~f([^~]*)~f', 'g'), '<b>$1</b>') // halbfett
  t = t.replace(new RegExp('~k([^~]*)~k', 'g'), '<h3>$1</h3>') // kursiv - Wir setzen hier Überschriften, da es eigentlich so genutzt wird
  return t
}

export function UnformatApoText(t: string) {
  Protos.forEach(p => {
    t = t.replace(p.rScope, p.rValue)
  })
  return t
}