// import {Html5QrcodeScanner, Html5QrcodeSupportedFormats} from "html5-qrcode"
import { useEffect, useState } from "react";
import UserDTO from "../../dto/UserDTO";
import userService from "../../services/UserService";
import BCancel from "../Buttons/BCancel";
import BLeft from "../Buttons/BLeft";
import Spinner from "../Spinner/Spinner";
import "./Scanner.scss";
import BarcodeScannerComponent from "./ScannerQuagga";
import { QuaggaJSResultObject } from "@ericblade/quagga2";

enum Status {
  scanning,
  loading,
}


export default function Scanner() {
  const [status, setStatus] = useState(Status.scanning)
  const [user, setUser] = useState(new UserDTO())
  const [c, setC] = useState('')
  
  useEffect(() => {
    userService.get().then(async (u) => {
      if (!u) { return }
      setUser(u)
      setStatus(Status.scanning)
    })
    
  }, [])
  
  return <>
    <div className='h-header '>
      <div>
        <BLeft
          target='/user/medikamentensuche'
        />
      </div>
      <div>PZN scannen:</div>
      <div>{c}</div>
      <div>
        <BCancel
          target='/user/medikamente'
        />
      </div>
    </div>
    <div className='h-main-content w3-container scannerHolder'>
      {
        status === Status.loading ? <Spinner /> :
        <BarcodeScannerComponent
          onUpdate={(a: QuaggaJSResultObject) => {
            console.log('onUpdate', a)
            const code = a.codeResult.code
            console.log('CODE', code)
            setC(code + '')
            const codeScope = /-([0-9]{8})/
            const scopeResult = code?.match(codeScope)
            if (scopeResult) {
              const pzn = scopeResult[1]
              user.drugProfiles.getDrugsInfoFromServer(pzn).then(drug => {
                // alert('asking db')
                if (drug[0]) {
                  // alert('did find something')
                  window.location.href = '/user/scanner/ergebnis/' + drug[0].pzn
                }
              })
            } else {
              // EAN
              const gtinScope = /([0-9]+)/
              const gtinResult = code?.match(gtinScope)
              if (gtinResult) {
                const gtin = gtinResult[1]
                user.drugProfiles.getDrugsInfoByGTINFromServer(gtin).then(drug => {
                  // alert('asking db')
                  if (drug[0]) {
                    // alert('did find something')
                    window.location.href = '/user/scanner/ergebnis/' + drug[0].pzn
                  }
                })
              }
              // user/drug/gtin
            }
          }}
        />
      }
    </div>
  </>
}
