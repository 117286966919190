import { currentShortDay } from '../components/Input/DaySelector'
import Com from '../services/ComService'
import { isWeekend } from '../services/DateService'
import FormatApoText from '../services/FormatApoText'
import { IProp } from './PropDTO'
import PropsDTO from './PropsDTO'

export default class DrugDTO {
  id: number = -1
  langname: string = ''
  pzn: string = ''
  verkehrsstatus: number = -1
  props: PropsDTO = new PropsDTO()
  public dosageForm: string = '' 

  constructor(data?: IDrug) {
    if (!data) { return }
    this.id = data.id
    this.langname = data.langname || ''
    this.pzn = data.pzn
    this.verkehrsstatus = data.verkehrsstatus || -1
    this.props = new PropsDTO(data.props || [], 'user/userdrug', {userDrugId: data.id})
    this.dosageForm = data.darreichungsform || this.dosageForm || ''
  }

  public getName() {
    return FormatApoText(this.langname)
  }

  public async getLeaflet() {
    return await Com('POST:drug/leafletByPzn', {
      pzn: this.pzn
    })
  }

  update(data: IDrug) {
    this.langname = data.langname || this.langname || ''
    this.verkehrsstatus = data.verkehrsstatus || this.verkehrsstatus || -1
    this.dosageForm = data.darreichungsform || this.dosageForm || ''
  }

  public getIntakes(d?: Date) {
    const intakeInterval = this.props.getPropValue1('intakeInterval', 'frequency')
    const differentOnWeekend = this.props.getPropValue1('times', 'differentOnWeekend') === 'active'

    if (intakeInterval === 'sometimes' || intakeInterval === 'cycle') {
      return []
    }

    const key1 = (differentOnWeekend && isWeekend(d) && intakeInterval !== 'daysofweek' && intakeInterval !== 'interval') ? 'times_weekend' : 'times_weekday'



    return this.props.getPropsByKey1(key1).filter(p => {
      if (intakeInterval === 'daysofweek') {
        const todayName = currentShortDay()
        const activeDays = this.props.getPropValue1(key1 + ':' + p.key2, 'days') || ''
        if (activeDays.search(todayName) === -1) { return false } 
      } else if (intakeInterval === 'interval') {
        let intervalStart = new Date(this.props.getPropValue1(key1 + ':' + p.key2, 'interval_start') || '')
        const interval = parseInt(this.props.getPropValue2(key1 + ':' + p.key2, 'interval_start') || '1', 10)
        let now = d? d : new Date()
        intervalStart.setHours(12)
        now.setHours(12)
        const difference = Math.round((now.getTime() - intervalStart.getTime()) / (1000*60*60*24))
        if (difference % interval !== 0) {
          return false
        }
      }
      return true
    }).map(p => {
      return {
        ...p, ...{drugId: this.id, sortNumber: parseInt(p.key2.replace(':', ''), 10)}
      }
    })
  }
}

export interface IDrug {
  id: number,
  langname?: string,
  pzn: string,
  verkehrsstatus?: number,
  props?: IProp[],
  darreichungsform?: string,
}

export enum ItemKind {
  explainText,
  dropDown,
  multiButtonRadio,
  drugTimeSlots,
}

export interface DrugConfigItem {
  kind: ItemKind,
  displayContent: string,
  displayOverview?: string,
  key1?: string,
  key2?: string,
  defaultValue?: string,
  other?: string,
  options?: {
    displayContent: string,
    value: string,
  }[]
}



export const DrugConfig = [
  {
    name: 'page1',
    items: [
      {
        kind: ItemKind.explainText,
        displayContent: 'Welche Darreichungsform hat dieses Medikament',
        displayOverview: 'Darreichungsform',
      },
      {
        kind: ItemKind.multiButtonRadio,
        displayContent: 'Dareichungsform',
        key1: 'drugInfo',
        key2: 'measureUnit',
        defaultValue: 'stk',
        other: 'andere',
        options: [
          {
            displayContent: 'Stück',
            value: 'stk'
          },
          {
            displayContent: 'Milliliter (ml)',
            value: 'ml'
          },
          {
            displayContent: 'Tropfen',
            value: 'drops'
          },
          {
            displayContent: 'Int. Einheiten (IE)',
            value: 'IE'
          },
        ]
      }
    ]
  },
  {
    name: 'page2',
    items: [
      {
        kind: ItemKind.explainText,
        displayContent: 'Mit welcher Regelmäßigkeit nehmen Sie dieses Medikament ein?',
        displayOverview: 'Einnahme Regelmäßigkeit',
      },
      {
        kind: ItemKind.multiButtonRadio,
        displayContent: 'Dareichungsform',
        key1: 'intakeInterval',
        key2: 'frequency',
        defaultValue: 'daily',
        options: [
          {
            displayContent: 'Täglich',
            value: 'daily'
          },
          {
            displayContent: 'Intervall',
            value: 'interval'
          },
          {
            displayContent: 'Tage in der Woche',
            value: 'daysofweek'
          },
          {
            displayContent: 'Zyklus',
            value: 'cycle'
          },
          {
            displayContent: 'Bei Bedarf',
            value: 'sometimes'
          },
        ]
      }
    ]
  },
  {
    name: 'page3',
    items: [
      {
        kind: ItemKind.explainText,
        displayContent: 'Wann nehmen Sie dieses Medikament ein?',
        displayOverview: 'Einnahme Zeitpunkte',
      },
      {
        kind: ItemKind.drugTimeSlots,
        displayContent: 'Dareichungsform',
        key1: 'times_{timeCategory}',
        key2: '{TIME}',
        value1: '{amount}',
        value2: '{unit}',
      }
    ]
  }
  
]