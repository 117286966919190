import { NavLink } from "react-router-dom";
import BHamburger from "../Buttons/BHamburger";
import BPlus from "../Buttons/BPlus";
import MyDrugs from "../Drugs/MyDrugs";
import MainMenu from "./MainMenu";
import ProfileSelector from "./ProfileSelector";
import { Plus } from "react-feather";
import { useEffect, useState } from "react";
import { PreferencesDTO } from "../../dto/PreferenceDTO";
import PrefContent from "../Preference/PrefContent";

enum Status {
  init,
  loading,
  done,
  success,
}

export default function Drugs() {
  const headline = 'Medikamente'
  return <>
    <div className='h-header'>
      <div><BHamburger /></div>
      <div className='mobile-headheadline'>{headline}</div>
      <div>
        <BPlus
          target='/user/medikamentensuche'
        />
      </div>
    </div>
    <div className='page-headline'>
      <h2>{headline}</h2>
      <ProfileSelector />
    </div>
    <div className='h-main-content'>
      <MyDrugs />
      <NavLink
        to={'/user/medikamentensuche'}
        className='h-flex-start h-flex-align-center no-text-decoration w3-margin'
        >
          <span
            className={`button-primary w3-margin-right`}
          >
            <Plus />
          </span>
          <div>
            Weitere Medikamente hinzufügen
          </div>
      </NavLink>
      <PrefContent className='w3-container' key2='medikamenteFooter' />
    </div>
    <MainMenu />
  </>
}