import { useEffect, useState } from "react";
import userService from "../../services/UserService";
import UserDTO from "../../dto/UserDTO";
import DropDown from "../Input/DropDown";
import './ProfileSelector.scss'
import { Bell, BellOff } from "react-feather";
import { pushDTO } from "../../dto/PushDTO";

enum Status {
  loading,
  standard,
}

export default function ProfileSelector() {
  const [status, setStatus] = useState(Status.loading)
  const [user, setUser] = useState(new UserDTO())
  const [profileHasNotifications, setprofileHasNotifications] = useState(false)
  const pushAvailable = 'serviceWorker' in navigator
  useEffect(() => {
    userService.get().then(async (u) => {
      if (!u) { return }
        setUser(u)
        setStatus(Status.standard)
        const hasNotifications = await u.drugProfiles.profileHasNotifications()
        setprofileHasNotifications(hasNotifications)
    })
  }, [])
  /*
  if (user.drugProfiles.profiles.length <= 1) {
    return null
  }
  */
  const lastElement = <div className='dropdown-option w3-padding' onClick={() => {
    window.location.href = '/user/profile'
  }}>Profile Verwalten</div>
  return <>
    <div className="profileSelector" title={`${pushAvailable ? 'push Available' : 'push not Available'}`}>
      {
        pushAvailable &&
        <button
          className={`w3-button`}
          title={`${profileHasNotifications ? 'Erinnerungen sind aktiviert - hier deaktivieren' : 'Erinnerungen sind deaktiviert - hier aktivieren'}`}
          onClick={async () => {
            if (profileHasNotifications) {
              setprofileHasNotifications(false)
              user.drugProfiles.profileSwitchOffNotifications()
            } else {
              setprofileHasNotifications(true)
              setprofileHasNotifications(
                await user.drugProfiles.profileSwitchOnNotifications() === true
              )
            }
          }}
        >
          {
            profileHasNotifications ? <Bell/> : <BellOff />
          }
        </button>
      }
      <DropDown
        value={user.drugProfiles.currentProfile}
        options={user.drugProfiles.profiles.map((p) => {
          return {
            displayValue: p.name,
            value: p.id
          }
        })}
        cb={async (id) => {
          await user.setCurrentProfile(id as number)
          console.log('do it')
          window.location.reload()
        }}
        lastElement={lastElement}
      />
    </div>
  </>
  
}