import Com from "../services/ComService"
import UserDTO, { IUser } from "./UserDTO"

export type Prop = {
  id: number,
  key1: string,
  key2: string,
  value1: string,
  value2: string,
}

export type FieldType = 'line' | 'text' | 'email' | 'phone'
export const FieldTypes: {
  name: string,
  type: FieldType
}[] = [
  {
    name: 'Eingabe',
    type: 'line'
  },
  {
    name: 'Text',
    type: 'text'
  },
  {
    name: 'EMail',
    type: 'email'
  },
  {
    name: 'Telefon',
    type: 'phone'
  }
]

export default class GroupDTO {
  id: number = -1
  name: string = ''
  description: string = ''
  users: UserDTO[] = []
  admins: number[] = []
  userId: number = -1
  props: Prop[] = []
  constructor(d: IGroup, options?: {userId?: number}) {
    this.init(d, options)
  }

  private init(d: IGroup, options?: {userId?: number}) {
    this.id = d.id
    this.name = d.name || ''
    this.description = d.description || ''
    if (d.users) {
      this.users = d.users.map(u => new UserDTO(u))
    }
    this.admins = d.admins || []
    this.userId = options?.userId || -1
    this.props = d.props || []
  }

  public async getFromServer() {
    const d = await Com('GET:true:ugroup/' + this.id, 2)
    this.init(d as IGroup)
  }

  public isAdmin(userId?: number): boolean {
    const id = (userId !== undefined) ? userId : this.userId
    return this.admins.indexOf(id) > -1
  }

  public async delete() {
    await Com('DELETE:ugroup', {
      id: this.id
    })
  }

  public async rename(name: string) {
    this.name = name
    await Com('PATCH:ugroup/name', {
      id: this.id,
      name: name
    })
  }
  
  public async addAdmin(id: number) {
    const r = await Com('PUT:ugroup/admin', {
      id: this.id,
      userId: id,
    })
    this.init(r as IGroup)
  }

  public async removeAdmin(id: number) {
    const r = await Com('DELETE:ugroup/admin', {
      id: this.id,
      userId: id,
    })
    this.init(r as IGroup)
  }

  public async addUser(id: number) {
    const r = await Com('PUT:ugroup/user', {
      id: this.id,
      userId: id,
    })
    this.init(r as IGroup)
  }

  public async addUserByMail(mail: string) {
    const r = await Com('PUT:ugroup/userByMail', {
      id: this.id,
      mail: mail,
    })
    this.init(r as IGroup)
  }

  public async removeUser(id: number) {
    const r = await Com('DELETE:ugroup/user', {
      id: this.id,
      userId: id,
    })
    this.init(r as IGroup)
  }
  public getAdmins() {
    return this.users.filter(u => this.admins.indexOf(u.id) !== -1)
  }

  public getStandardUsers() {
    return this.users.filter(u => this.admins.indexOf(u.id) === -1)
  }

  public async setProp(p: Prop) {
    const prop = this.getProp(-1, p.key1, p.key2)
    if (prop) {
      prop.value1 = p.value1
      prop.value2 = p.value2
    } else {
      this.props.push(p)
    }
    await this.saveProp(p)
  }

  public hasProp(p: {key1: string, key2: string}) {
    return this.props.findIndex(prop => prop.key1 === p.key1 && prop.key2 === p.key2) > -1
  }

  public countFields() {
    return this.props.filter(p => p.key1 === 'field').length
  }

  public getFields() {
    return this.props.filter(p => p.key1 === 'field').sort((a, b) => parseInt(a.value2, 10) - parseInt(b.value2, 10))
  }

  public getProp(id: number, key1?: string, key2?: string) {
    if (key1 && key2) {
      return this.props.find(f => f.key1 === key1 && f.key2 === key2)
    }
    return this.props.find(f => f.id === id)
  }

  public getPropV1(key1: string, key2: string, fb?: string) {
    const prop = this.getProp(-1, key1, key2)
    if (prop) {
      return prop.value1
    }
    return fb || ''
  }

  public async addField() {
    this.props.push({
      id: -1,
      key1: 'field',
      key2: '',
      value1: '',
      value2: `${this.countFields() + 1}0`
    })
  }

  public async nameField(id: number, name: string) {
    const f = this.getProp(id)
    if (!f) { return }
    f.key2 = name
    await this.saveProp(f)
  }

  public async setFieldType(id: number, t: FieldType) {
    const f = this.getProp(id)
    if (!f) { return }
    f.value1 = t
    await this.saveProp(f)
  }

  public async moveField(id: number, dir: -1 | 1) {
    const f = this.getProp(id)
    if (!f) { return }
    const p = parseInt(f.value2, 10)
    const np = p + (dir * 15)
    f.value2 = `${np}`
    this.props = this.getFields().map((g, i) => {
      return {
        id: g.id,
        key1: g.key1,
        key2: g.key2,
        value1: g.value1,
        value2: `${i + 1}0`
      }
    })
    // now Save ALL fields!
    for (let i = 0, m = this.props.length; i < m; i++) {
      await this.saveProp(this.props[i])
    }
  }

  public async saveProp(p: Prop) {
    const r = await Com('PATCH:ugroup/prop', {
      id: this.id,
      propId: p.id,
      key1: p.key1,
      key2: p.key2,
      value1: p.value1,
      value2: p.value2
    }) as Prop
    if (p.id < 0) {
      const f = this.getProp(p.id)
      if (f) {
        f.id = r.id
      } else {
        this.props.push({...p, ...{
          id: r.id
        }})
      }
    }
    console.log('save result', r)
  }
  public async deleteProp(id: number) {
    const r = await Com('DELETE:ugroup/prop', {
      id: this.id,
      propId: id
    })
    this.props = this.props.filter(p => p.id !== id)
    console.log('delete result', r)
  }

  public async deletePropByKey1Key2(key1: string, key2: string) {
    const prop = this.getProp(-1, key1, key2)
    if (!prop) { return }
    await this.deleteProp(prop.id)
  }

  public getUrl() {
    return this.props.find(p => p.key1 === 'url')?.value1 || ''
  }

  public async setUrl(url: string) {
    const urlId = this.props.find(p => p.key1 === 'url')?.id || -1
    const r = await Com('PATCH:ugroup/prop', {
      id: this.id,
      propId: urlId,
      key1: 'url',
      key2: 'generic',
      value1: url,
      value2: ''
    }) as Prop
    const prop = this.props.find(p => p.id === urlId)
    if (prop) {
      prop.value1 = url
    } else {
      this.props.push(r)
    }
  }
}

export interface IGroup {
  id: number,
  name?: string,
  description?: string
  users?: IUser[]
  admins?: number[]
  props?: Prop[]
}
