import { useEffect, useState } from "react";
import { Plus, Trash } from "react-feather";
import DrugDTO from "../../dto/DrugDTO";
import UserDTO from "../../dto/UserDTO";
import userService from "../../services/UserService";
import DrugInteraction from "./DrugInteraction";
import DrugSearch from "./DrugSearch";
import { DosageDisplayName } from "../../dto/DosageForm";
import FormatApoText from "../../services/FormatApoText";

enum Status {
  loading,
  standard,
  search,
  interactions,
}

export default function MyDrugList (props: {}) {
  const [status, setStatus] = useState(Status.loading)
  const [user, setUser] = useState(new UserDTO())
  useEffect(() => {
    userService.get().then((u) => {
      if (!u) { return }
        setUser(u)
        setStatus(Status.standard)
    })
  }, [])
  // const user = userService.get()
  if (status === Status.loading) {
    return <div>Lade ...</div>
  }
  async function deletePzn(pzn: string) {
    setStatus(Status.loading)
    await user.drugProfiles.removePzn(pzn)
    setStatus(Status.standard)
  }
  if (status === Status.interactions) {
    return <div>
      <button
        onClick={() => {
          setStatus(Status.standard)
        }}
      >Zurück zur Liste</button>
      <DrugInteraction />
    </div>
  }
  return <div>
    <h1>Meine Medikamente</h1>
    {
      status !== Status.search &&
      <button
        className='button-primary'
        onClick={() => {
          setStatus(Status.search)
        }}
      ><Plus /></button>
    }
    {
      status === Status.search &&
      <DrugSearch
        set={async (pzn: string) => {
          await user.drugProfiles.addPzn(pzn)
          setStatus(Status.standard)
        }}
      />
    }
    {
      user.drugProfiles.getDrugs().map((d, index) => <Drug
          key={`drug-list-pzn-${index}`}
          Drug={d}
          delete={deletePzn}
        />)
    }
    <button
      onClick={() => {
        setStatus(Status.interactions)
      }}
    >Wechselwirkungen berechnen</button>
  </div>
}

function Drug (props: {
  Drug: DrugDTO,
  delete: (pzn: string) => void
}) {
  const drug = props.Drug
  console.log('drug', drug)
  return <div
    className='w3-card w3-margin w3-padding w3-row'
  >
    <div className='w3-col s10 m11'>
      <div>
        {
          drug.pzn
        }
      </div>
      <div>
        {FormatApoText(drug.langname)}
      </div>
      <div>
        Darreichungsform:&nbsp;
        {
          DosageDisplayName(drug.dosageForm)
        }
      </div>
    </div>
    <button
      className='w3-col s2 m1'
      onClick={() => {
        if (!window.confirm(`${FormatApoText(drug.langname)} wirklich entfernen?`)) { return }
        props.delete(drug.pzn)
      }}
    >
      <Trash />
    </button>
  </div>
}
