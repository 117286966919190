import Menu from "../Menu/Menu";
import menuConfig from '../../config/userMenu.json'

export default function MainMenu(props: {
  className?: string,
}) {
  return <div className={`h-footer h-bg-white-shadow ${props.className || ''}`}>
    <Menu
      config={menuConfig}
    />
  </div>
}
