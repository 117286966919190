import { ArrowLeft } from "react-feather";
import { NavLink } from "react-router-dom";
import './Button.scss'

export default function BLeft(props: {
  target?: string,
  onClick?: () => void,
}) {
  if (props.target) {
    return <NavLink
    to={props.target}
    className={`h-standardButton`}
  >
    <ArrowLeft />
  </NavLink>
  }
  return <div
    className='h-standardButton'
    onClick={() => {
      if (props.onClick) {
        props.onClick()
      }
    }}
  >
    <ArrowLeft />
  </div>
}
