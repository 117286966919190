import { Maximize } from "react-feather";
import { NavLink } from "react-router-dom";

export default function ScannerButton(props: {

}) {
  return <div className='barcode-link-holder w3-margin'>
    <NavLink
      to={'/user/scanner'}
    > 
      <button
        className='h-main-button'><Maximize /> Barcode Scanner</button>
    </NavLink>
  </div>
}