import { useState } from "react";
import { onMessageListener } from "../services/firebase";
import { X } from "react-feather";
import './FirebaseToast.scss'
import broadcastService from "../services/BroadcastService";

export default function FirebaseToast(props: {

}) {
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({title: '', body: ''});
  // const [isTokenFound, setTokenFound] = useState(false);
  // getToken(setTokenFound);

  onMessageListener().then((payload: any) => {
    setShow(true);
    setNotification({title: payload.notification.title, body: payload.notification.body})
    console.log(payload);
    setTimeout(() => {
      setShow(false)
    }, 6000)
  }).catch(err => console.log('failed: ', err));
  broadcastService.subscribe('toaster', (key, body) => {
    if (key !== 'toastMsg') {
      return
    }
    setShow(true);
    setNotification({title: body.title, body: body.body})
    setTimeout(() => {
      setShow(false)
    }, 6000)
  })

  return <div className={`FirebaseToast w3-padding ${show ? 'active' : ''}`}>
    <h3>{notification.title}</h3>
    <div>{notification.body}</div>
    <div>
      <button
        className='button-primary'
        onClick={() => {
          setShow(false)
        }}
      >Verstanden</button>
    </div>
  </div>
}
