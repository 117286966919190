import { Link } from "react-router-dom"
import Login from "../Login/Login"
import './Landing.scss'

const Landing = () => {
  return <>
    <div className='landing-header'>
      <h1>Willkommen!</h1>
      <p>Anmelden und einfach loslegen</p>
    </div>
    <Login></Login>
    <div></div>
  </>
}
export default Landing