import UserDTO, { IUser } from "../dto/UserDTO"
import broadcastService from "./BroadcastService"
// import Com from './ComService'
import loginService from "./LoginService"


class UserService {
  user: UserDTO | null = null
  isLoading: boolean = false
  resultCue: ((u: UserDTO | null) => void)[] = []
  constructor() {
    broadcastService.subscribe('userService', (key, body) => {
      if (key === 'login') {
        this.get()
      }
      if (key === 'logout') {
        this.clear()
      }
    })
  }

  public async get(): Promise <UserDTO | null> {
    const isLoggedIn = await loginService.IsLoggedIn()
    if (!isLoggedIn) {
      window.location.href = '/'
      return null
    }
    if (isLoggedIn && !this.isLoading && this.user) { return this.user}
    if (this.isLoading) {
      return new Promise(done => {
        this.resultCue.push((user: UserDTO | null) => done(user))
      })
    }
    this.isLoading = true
    const user = loginService.user
    // const raw = await Com('GET:user')
    // const user = new UserDTO(raw as IUser)
    await user?.drugProfiles.getDrugsFromServer()
    this.resultCue.forEach(r => r(user))
    this.resultCue = []
    this.isLoading = false
    this.user = user
    return user
  }

  public clear() {
    this.user = null
  }
}

const userService = new UserService()
export default userService
