import { useState } from 'react'
import broadcastService from '../../services/BroadcastService'
import './HamburgerMenu.scss'
import { X } from 'react-feather'
import Menu from './Menu'
import menuConfig from '../../config/hamburgerMenu.json'

export default function HamburgerMenu (props: {}) {
  const className = 'hamburgerMenu w3-container w3-padding '
  const [openClass, setOpenClass] = useState('')
  broadcastService.subscribe('HamburgerMenu', (key, body) => {
    if (key === 'plzOpenHamburger') {
      setOpenClass('openHamburgerMenu')
    } else if (key === 'plzCloseHamburger') {
      setOpenClass('')
    }
    
  })
  return <div className={className + openClass}>
    <div>
      <button
        className='button-secondary'
        onClick={() => {
          setOpenClass('')
        }}
      ><X /></button>
    </div>
    <Menu config={menuConfig} />
  </div>
}