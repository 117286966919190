import { Menu } from "react-feather";
import './Button.scss'
import broadcastService from "../../services/BroadcastService";

export default function BHamburger(props: {
  
}) {
  return <div
    className='h-standardButton'
    onClick={() => {
      broadcastService.broadcast('plzOpenHamburger')
    }}
  >
    <Menu />
  </div>
}
