// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// import config from '../config.json'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAExrLRE90Vl9fD9S0LSmi0IpgeGDcP3BM",
  authDomain: "nexave-aporize.firebaseapp.com",
  projectId: "nexave-aporize",
  storageBucket: "nexave-aporize.appspot.com",
  messagingSenderId: "875767051926",
  appId: "1:875767051926:web:fd1f9760cdb5fa135274b7"
};

// const push_subscription = config.apiPrefix + 'push_subscription'
type HTTPMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'OPTIONS' | 'HEAD' | 'PATCH';

// const contentEncoding = (PushManager.supportedContentEncodings || ['aesgcm'])[0];

// Initialize Firebase

const publicKey = "BNKSd4TIJvfK51jtuJz0eNSzU46ahoR5-VKJz13eK3peOaKkjijEKuGwl6rnLEbbzoBa1yz6ri5mP1VOyu8whiM"
// const endpoint = 'https://fcm.googleapis.com//v1/projects/nexave-aporize/messages:send'


class MyFirebase {
  firebase
  firebaseMessaging
  constructor() {
    this.firebase = initializeApp(firebaseConfig);
    this.firebaseMessaging = getMessaging(this.firebase);
  }

  async getToken(): Promise <string> {
    return new Promise((success) => {
      // Add the public key generated from the console here.
      getToken(this.firebaseMessaging, {vapidKey: publicKey}).then((currentToken) => {
        if (currentToken) {
          // Send the token to your server and update the UI if necessary
          console.log('current token for client: ', currentToken);
          success(currentToken)
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
          // ...
          success('err')
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
        success('err')
      });
    })
  }
  
  async requestPermission() {
    console.log('Requesting permission...');
    return new Promise((success) => {
      Notification.requestPermission().then(async (permission) => {
        if (permission === 'granted') {
          console.log('Notification permission granted.');
          const token = await this.getToken()
          success(token)
        }
      }).catch(() => {
        success('no')
      })
    })
  }
}

export const myFirebase = new MyFirebase()
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(myFirebase.firebaseMessaging, (payload) => {
      resolve(payload);
    });
});
