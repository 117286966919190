import { NavLink } from "react-router-dom";
import './Button.scss'

export default function BCancel(props: {
  target?: string,
  onClick?: () => void,
  displayValue?: string,
}) {
  const str = props.displayValue || 'Abbrechen'
  if (props.target) {
    return <NavLink
    to={props.target}
    className={`h-standardButton`}
  >
    <span>{str}</span>
  </NavLink>
  }
  return <button
    className='h-standardButton'
    onClick={() => {
      if (props.onClick) {
        props.onClick()
      }
    }}
  >
    {str}
  </button>
}
