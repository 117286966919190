import Com from "../services/ComService";
import { twoDigits } from "../services/DateService";
import DrugDTO, { IDrug } from "./DrugDTO";
import DrugInteractionsDTO from "./DrugInteractionsDTO";
import PropDTO from "./PropDTO";
import PropsDTO from "./PropsDTO";

export type Intake = {
  key1: string,
  key2: string,
  value1: string,
  value2: string,
  drugId: number,
  sortNumber: number,
}

export class DrugProfileDTO {
  id: number = -1
  name: string = 'Standard'
  props: PropsDTO
  drugs: DrugDTO[] = []
  drugInteractions = new DrugInteractionsDTO()

  constructor(data: IDrugProfile) {
    this.id = data.id
    this.name = data.name
    this.props = new PropsDTO(data.props, 'user/drugProfile/prop', {
      drugProfileId: this.id
    })
    this.drugs = data.drugs.map(d => new DrugDTO(d))
  }

  public async rename(name: string) {
    this.name = name
    const result = await Com('PATCH:user/drugProfile', {
      id: this.id,
      name: name
    })
    return result
  }

  public async addPzn(pzn: string) {
    /*
    const drug = this.drugs.find(d => d.pzn === pzn)
    if (drug) {
      return drug
    }
    */
    const result = await Com('PUT:user/drug', {
      drugProfileId: this.id,
      pzn: pzn
    })
    this.drugs = this.drugs.filter(d => d.pzn !== pzn)
    if (!result) { return {id: -1}}
    this.drugs.push(new DrugDTO(result as IDrug))
    return result
  }

  public async removePzn(pzn: string) {
    const result = await Com('DELETE:user/drug', {
      drugProfileId: this.id,
      pzn: pzn
    })
    this.drugs = this.drugs.filter(d => d.pzn !== pzn)
  }

  public async getDrugsFromServer() {
    const pzns = this.getPznString()
    const result = await Com('POST:user/drug/list', {
      pzns: pzns
    }) as IDrug[]
    // Update Druglist with information from server:
    this.drugs.forEach(d => {
      const r = result.find(r => r && d.pzn === r.pzn)
      if (r) {
        d.update(r)
      }
    })
    // Only update Drugs:
    result.filter(d => !!d).forEach(r => {
      this.drugs.find(d => d.pzn === r.pzn)?.update(r)
    })
    // this.drugs = result.filter(d => !!d).map(d => new DrugDTO(d))
  }

  public getPzns() {
    return this.drugs.map(d => d.pzn)
  }

  public getPznString() {
    return this.getPzns().join(';')
  }

  public drugInfo(pzn: string) {
    return this.drugs.find(d => d.pzn === pzn)
  }

  public getDrugs() {
    return this.drugs
  }

  public async calcDrugInteractions() {
    await this.drugInteractions.getFromServer(this.getPznString())
  }

  public getUserdrugById(id: number): DrugDTO | null {
    return this.drugs.find(d => d.id === id) || null
  }

  public getTodayIntakes(d?: Date) {
    const now = d ? d : new Date()
    const hour = now.getHours()
    const minute = now.getMinutes()
    const stamp = parseInt(`${twoDigits(hour)}${twoDigits(minute)}`, 10)
    let intakes: Intake[] = []
    this.drugs.forEach(drug => {
      intakes = intakes.concat(drug.getIntakes(d))
    })
    intakes = intakes.sort((a, b) => {
      return a.sortNumber - b.sortNumber
    })
    return {
      past: intakes.filter(i => i.sortNumber <= stamp),
      upcoming: intakes.filter(i => stamp < i.sortNumber),
    }
  }

}

export interface IDrugProfile {
  id: number
  name: string
  props: PropDTO[]
  drugs: IDrug[]
}
