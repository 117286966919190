import { NavLink } from "react-router-dom";
import i18n from "../../services/i18n";
import './Button.scss'

export default function BMain(props: {
  target?: string,
  content: string,
  onClick?: () => void,
}) {
  const className = 'h-main-button h-standardButton'
  return <div className='h-content'><div className='w3-container'>
    { props.target &&
      <NavLink
        to={props.target}
        className={className}
      >
        {i18n.get(props.content)}
      </NavLink>
    }
    {
      !props.target &&
      <button
        className={className}
        onClick={() => {
          if (props.onClick) {
            props.onClick()
          }
        }}
      >{i18n.get(props.content)}</button>
    }
  </div></div>
}
