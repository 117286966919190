import aaui from 'array_add_unique_item'

type MemberContent = (key: string, body?: any) => void
type Member = {
  name: string,
  content: MemberContent
}

class BroadcastService {
  members: Member[] = []
  subscribe (name: string, member: MemberContent) {
    aaui(this.members, [{name: name, content: member}], ['name'])
  }
  unsubscribe (name: string) {
    this.members = this.members.filter(m => m.name != name)
  }
  broadcast(key: string, body?: any) {
    console.log('broadcast', key, body)
    this.members.forEach(m => {
      try {
        m.content(key, body)
      } catch(err) {
        this.unsubscribe(m.name)
      }
    })
  }
}

const broadcastService = new BroadcastService()
export default broadcastService
