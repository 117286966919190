import { NavLink, Outlet } from "react-router-dom"
import DrugSearch from "../Drugs/DrugSearch"
import { useEffect, useState } from "react"
import UserDTO from "../../dto/UserDTO"
import userService from "../../services/UserService"
import broadcastService from "../../services/BroadcastService"
import { User } from "react-feather"

enum Status {
  loading,
  standard,
}

const UserLayout = () => {
  const [user, setUser] = useState(new UserDTO())
  const [status, setStatus] = useState(Status.loading)
  useEffect(() => {
    userService.get().then((u) => {
      if (!u) { return }
        setUser(u)
    })
  }, [])
  return <div className='UserLayoutHolder'>
    <div className="h-header-desktop">
      <NavLink className='h-logo no-text-decoration' to={'/user/home'}>APORIZE</NavLink>
      <div className='h-top-search'>
        <DrugSearch
          set={async (pzn: string) => {
            // Set means save to user and then proceed to detail page
            const result = await user.drugProfiles.addPzn(pzn) as {id: number}
            window.location.href = '/user/medikament/einstellungen/' + result.id
          }}
          hideMainWithoutInput={true}
        />
      </div>
      <div className='h-user-name-drop-down'>
        <div
          className='h-user-button'
          onClick={() => {
            broadcastService.broadcast('plzOpenHamburger')
          }}
        >
          <User className='h-user-avatar'/>
          <span className='h-user-name'>
            {user.email}
          </span>
          <div className='triangle-holder'>
            <div className='triangle'></div>
          </div>
        </div>
      </div>
    </div>
    <Outlet></Outlet>
  </div>
}
export default UserLayout
