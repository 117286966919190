import { useEffect, useState } from "react"
import userService from "../../services/UserService"
import UserDTO from "../../dto/UserDTO"
import { printUserTime } from "../../services/DateService"
import { Intake } from "../../dto/DrugProfileDTO"
import { date2UserDate } from "../../services/DateService"
import { printWeekday } from "../../services/DateService"
import FormatApoText from "../../services/FormatApoText"
import broadcastService from "../../services/BroadcastService"
import DrugIcon from "../Icons/DrugIcon"

enum Status {
  loading,
  standard,
  notFound,
}

type DayInfo = {
  todayDrugs: {
    past: Intake[],
    upcoming: Intake[],
  } | null,
  isToday: boolean,
  date: Date
}

function getDisplayDates() {
  const amount = 7
  let today = new Date(new Date().setDate(new Date().getDate() - 1))
  let out = []
  for (let i = 0, m = amount; i < m; i++) {
    out.push({
      isToday: i === 1,
      date: today
    })
    today = new Date(new Date().setDate(today.getDate() + 1))
  }
  return out
}

export default function DrugPlan (props: {

}) {
  const [status, setStatus] = useState(Status.loading)
  const [user, setUser] = useState(new UserDTO())
  const drugProfiles = user.drugProfiles
  const dates = getDisplayDates()
  const drugDates: DayInfo[] = dates.map((d) => {
    return {
      todayDrugs: drugProfiles.getTodayIntakes(d.date),
      isToday: d.isToday,
      date: d.date
    }
  })
  // const todayDrugs = drugProfiles.getTodayIntakes()
  useEffect(() => {
    userService.get().then((u) => {
      if (!u) { return }
      setUser(u)
      setStatus(Status.standard)
    })
  }, [])
  return <>
    {
      status === Status.standard &&
      drugDates.map(dd => {
        return DisplayDate({
          dayInfo: dd,
          user: user
        })
      })
    }
  </>
}


function DisplayDate(props: {
  dayInfo: DayInfo,
  user: UserDTO,
}) {
  const todayDrugs = props.dayInfo.todayDrugs
  const user = props.user
  const isToday = props.dayInfo.isToday
  const date = props.dayInfo.date
  function DisplayH2() {
    return <h2 className="w3-container">
      {isToday && <span>Heute</span>}
      {!isToday && <span>{printWeekday(date)}</span>}
      <span> - </span>
      {date2UserDate(date)}
    </h2>
  }
  if (todayDrugs === null) {
    return <>
      <DisplayH2 />
      <div className="info-box w3-margin">Hier keine Einnahme</div>
    </>
  }
  return <>
    <DisplayH2 />
    {
      todayDrugs.past.map((intake, index) => {
        return <RenderIntake
          key={`past-${intake.key2}-${index}`}
          keykey={`past-${intake.key2}-${index}`}
          intake={intake}
          user={user}
          className={`h-list-item w3-card w3-margin w3-padding ${isToday ? '' : 'h-list-item-grey'}`}
        />
      })
    }
    {
      isToday &&
      <>
        <hr></hr>
        <label className='w3-padding'>Jetzt - {printUserTime(new Date())}</label>
        <hr></hr>
      </>
    }
    {
      todayDrugs.upcoming.map((intake, index) => {
        return <RenderIntake
          key={`upcoming-${intake.key2}-${index}`}
          keykey={`upcoming-${intake.key2}-${index}`}
          intake={intake}
          user={user}
          className={`h-list-item w3-card w3-margin w3-padding ${isToday ? '' : 'h-list-item-grey'}`}
        />
      })
    }
  </>
}

function RenderIntake(props: {
  intake: Intake,
  user: UserDTO,
  className: string,
  keykey: string,
}) {
  const intake = props.intake
  const user = props.user
  const drug = user.drugProfiles.getUserdrugById(intake.drugId)
  const [time, setTime] = useState(intake.key2)
  const [amount, setAmount] = useState(intake.value1)
  const [show, setShow] = useState(true)
  if (drug === null) { return null }
  
  const measureUnit = drug.props.getPropValue1('drugInfo', 'measureUnit')
  // eslint-disable-next-line react-hooks/rules-of-hooks
  broadcastService.subscribe('DrugPlan-' + props.keykey, (key, body: any) => {
    console.log('drug', drug, body, intake)
    if (key !== 'rerenderDrugPlan') { return }
    if (body.id === drug.id) {
      // This might be wrong if the plan looks more complicated!!! we need a better solution!
      // setShow(false)
      setTime(body.time)
      setAmount(body.amount)
      // setTimeout(() => {setShow(true)}, 500)
    }
  })
  if (!show) { return null }
  return <div
    className='w3-card w3-margin h-list-item h-drugCard'
    onClick={() => {
      broadcastService.broadcast('drawer', {
        drugId: drug.id,
        pzn: drug.pzn,
        kind: 'druginfo',
        name: drug.getName(),
      })
    }}
  >
    <div className='drugChild drugIcon'><DrugIcon drug={drug} /></div>
    <div className='drugChild drugChildMainElement no-round' title={drug.pzn}>
      <div>
        {
          FormatApoText(drug.langname)
        }
        
      </div>
      <div className='SlotDisplayAmount'>{amount} {measureUnit}</div>
    </div>
    <div className='drugChild drugChildTime'>
      <div>{time}</div>
    </div>
  </div>
}