import Com from "../services/ComService"
import config from '../config.json'

export default class FileDTO {
  id: number = -1
  name: string = ''
  filename: string = ''
  constructor(o: IFile) {
    this.init(o)
  }
  init(o: IFile) {
    this.id = o.id
    this.name = o.name
    this.filename = o.filename
  }
  getPath() {
    return `${config.mediaDir}${this.filename}`
  }
  getCSSString() {
    return `url("${this.getPath()}")`
  }
  public async delete() {
    await Com('DELETE:file', {
      id: this.id
    })
  }
}

export interface IFile {
  id: number,
  name: string,
  filename: string,
}

export class FilesDTO {
  kind: string = 'userPictures'
  files: FileDTO[] = []
  constructor(o?: {kind?: string}) {
    this.kind = o?.kind || 'userPictures'
  }

  async getFromServer() {
    console.log('get pictures from server')
    const r = await Com(this.getPath()) as {files: FileDTO[]}
    console.log('got files', r.files)
    this.files = r.files.map(f => new FileDTO(f))
    return this.files
  }

  getPath() {
    switch(this.kind) {
      case 'userPictures':
        return 'GET:user/pictures'
    }
    return 'GET:user/pictures'
  }

  public async deleteFile(id: number) {
    const file = this.files.find(f => f.id === id)
    if (!file) { return }
    await file.delete()
    this.files = this.files.filter(f => f.id !== id)
  }
}